import React, { useState } from 'react'
import './MessageSentModal.css'
import Modal from 'react-bootstrap/Modal';
import ContactLottie from '../CommonComponents/Lotties/ContactLottie/ContactLottie';


const MessageSentModal = (props) => {

    const [modalShow, setModalShow] = useState(false)

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='MessageSentModal'
            >

                <Modal.Body>

                    <div className='main_div'>
                        <div className='success_img_holder'>
                            <img
                                className="logo_img"
                                src={
                                    process.env.PUBLIC_URL + "/Assets/Images/Common/success.png"
                                }
                            />
                        </div>
                        <p className='descn'>{props?.message} </p>
                    </div>


                </Modal.Body>

            </Modal>
        </>
    )
}

export default MessageSentModal