import React from 'react'
import "./TransformationProgram.css";

const TransformationProgram = (props) => {
    return (
        <section className='TransformationProgram'>

            <div className='bg_div'>
                <div className='heading_div'>
                    <div className='first_div'>
                        <p>{props?.left_title}</p>
                    </div>
                    <div className='second_div'>
                        <p>{props?.right_title}</p>
                    </div>
                </div>

                <div className='content_div'>
                    <div className='step_main_div'>

                        {props?.transformation_program?.map((item, index) => {
                            return (
                                <div className='step_sub_div' key={index}>
                                    <div className='bullet'></div>
                                    <div className='program_text'>
                                        <p>{item}</p>
                                    </div>
                                </div>
                            )
                        })}


                    </div>
                </div>
            </div>
        </section>
    )
}

export default TransformationProgram
