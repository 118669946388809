import React from "react";
import { Button, Modal } from "react-bootstrap";
import "./OurServiceModal.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleArrowRight } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Pagination } from "swiper/modules";

const OurServiceModal = (props) => {
  const navigate = useNavigate("");

  return (
    <Modal
      {...props}
      size="xl"
      dialogClassName="modal-2xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="service-modal"
    >
      <Modal.Body>
        <section className="OurServiceModal">
          <div className="container-fluid p-0">
            <div className="main_div">
              <div className="card_holder">
                <Swiper
                  pagination={{
                    dynamicBullets: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper service-slider"
                  slidesPerView={3}
                  breakpoints={{
                    280: {
                      slidesPerView: 1,
                      spaceBetween: 10,
                    },
                    640: {
                      slidesPerView: 2,
                      spaceBetween: 10,
                    },
                    768: {
                      slidesPerView: 2,
                      spaceBetween: 20,
                    },
                    1024: {
                      slidesPerView: 3,
                      spaceBetween: 30,
                    },
                    1280: {
                      slidesPerView: 4,
                      spaceBetween: 40,
                    },
                  }}
                >
                  <SwiperSlide>
                    {" "}
                    <div className="card">
                      <div className="img_holder">
                        <img
                          className="logo_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/Assets/Images/OurService/card1.png"
                          }
                        />
                      </div>

                      <div className="text_container">
                        <h3>1-Month Boot Camp</h3>
                        <div className="card-dody">
                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>15 min pranayama + stretching</p>
                            </div>
                          </div>

                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>35 min meditation</p>
                            </div>
                          </div>

                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>15 min journaling</p>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="btn_holder">
                            <Button
                              onClick={() => {
                                props.onHide();
                                navigate("/our-services");
                              }}
                              className="submit_btn"
                              type="submit"
                            >
                              Explore More{" "}
                              <span>
                                {" "}
                                <img
                                  className="logo_img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/Assets/Images/OurService/arrow.png"
                                  }
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="card">
                      <div className="img_holder">
                        <img
                          className="logo_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/Assets/Images/OurService/card2.png"
                          }
                        />
                      </div>

                      <div className="text_container">
                        <h3>Online pranayam for teen age childrens</h3>
                        <div className="card-dody">
                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>Basics/theories behind pranayam</p>
                            </div>
                          </div>

                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>Types of pranayam</p>
                            </div>
                          </div>

                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>Advantages of pranayam</p>
                            </div>
                          </div>

                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>Do’s and Don’t of pranayam</p>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="btn_holder">
                            <Button
                              onClick={() => {
                                props.onHide();
                                navigate("/our-services/onilne-pranayam");
                              }}
                              className="submit_btn"
                              type="submit"
                            >
                              Explore More{" "}
                              <span>
                                {" "}
                                <img
                                  className="logo_img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/Assets/Images/OurService/arrow.png"
                                  }
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="card">
                      <div className="img_holder">
                        <img
                          className="logo_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/Assets/Images/OurService/card3.png"
                          }
                        />
                      </div>

                      <div className="text_container">
                        <h3>Online Advance Pranayam Course</h3>
                        <div className="card-dody">
                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>Basics/theories behind pranayama</p>
                            </div>
                          </div>

                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>Types of pranayam</p>
                            </div>
                          </div>

                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>Advantages of pranayam</p>
                            </div>
                          </div>

                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>Do’s and Don’t of pranayam</p>
                            </div>
                          </div>
                        </div>

                        <div className="card-footer">
                          <div className="btn_holder">
                            <Button
                              onClick={() => {
                                props.onHide();
                                navigate("/our-services/advance-pranayam");
                              }}
                              className="submit_btn"
                              type="submit"
                            >
                              Explore More{" "}
                              <span>
                                {" "}
                                <img
                                  className="logo_img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/Assets/Images/OurService/arrow.png"
                                  }
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    {" "}
                    <div className="card">
                      <div className="img_holder">
                        <img
                          className="logo_img"
                          src={
                            process.env.PUBLIC_URL +
                            "/Assets/Images/OurService/card4.png"
                          }
                        />
                      </div>

                      <div className="text_container">
                        <h3>Swar Yoga for Self Realization</h3>

                        <div className="card-dody">
                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>Duration is 15 days</p>
                            </div>
                          </div>

                          <div className="activty_holder">
                            <div className="icon_holder">
                              <img
                                className="logo_img"
                                src={
                                  process.env.PUBLIC_URL +
                                  "/Assets/Images/Home/ExploreOffer/arrow.png"
                                }
                              />
                            </div>
                            <div className="text_holder">
                              <p>Regular Theory Class & pranayam</p>
                            </div>
                          </div>
                        </div>
                        <div className="card-footer">
                          <div className="btn_holder">
                            <Button
                              onClick={() => {
                                props.onHide();
                                navigate("/our-services/swaryoga");
                              }}
                              className="submit_btn"
                              type="submit"
                            >
                              Explore More{" "}
                              <span>
                                {" "}
                                <img
                                  className="logo_img"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/Assets/Images/OurService/arrow.png"
                                  }
                                />
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </section>
      </Modal.Body>
    </Modal>
  );
};

export default OurServiceModal;
