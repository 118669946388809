import { faCircleArrowRight, faL } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button } from "react-bootstrap";
import "./BannerTypeOne.css";

import "../../CommonCss/CommonCss.css";
import { useNavigate } from "react-router-dom";
import ContactLottieModal from "../../CommonModal/ContactLottieModal";

const BannerTypeOne = ({btnText , ...props}) => {
  const [modalShow, setModalShow] = useState(false);

  const navigate = useNavigate("");

  console.log("props?.redirectTo", props?.redirectTo,document.body.scrollHeight);

  const callFunction = () => {
    if (props?.redirectTo !== undefined) {
      navigate(props?.redirectTo);
    }

    else if (props?.scroll) {
      window.scrollTo({
        top: 1500, // Scroll to the bottom of the page
        behavior: "smooth", // Smooth scrolling animation
      });
    }
    else {
      setTimeout(() => {
        setModalShow(false);
      }, 3000);
      setModalShow(true);
    }
  };

  return (
    <div className="BannerTypeOne">
      <div className="image_holder p-0">
        <img
          className="ban_image"
          src={process.env.PUBLIC_URL + props?.src}
          alt="Logo"
        />
      </div>

      <div className="btn_holder">
        <Button
          onClick={() => {
            callFunction();
          }}
          className="submit_btn"
          type="submit"
        >
       {btnText}
          <FontAwesomeIcon icon={faCircleArrowRight} className="arrow-icon" />
        </Button>
      </div>

      <ContactLottieModal show={modalShow} onHide={() => setModalShow(false)} />
    </div>
  );
};

export default BannerTypeOne;
