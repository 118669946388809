import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import ContactLottieModal from '../../../CommonModal/ContactLottieModal'

const EnrollSection = (props) => {

    const [modalShow,setModalShow] = useState(false)


    return (
        <>
            <h1 className='title_text'>How to Join?</h1>
            <div className='enroll_div'>


                <div className='text_holder_main'>

                    <div className='left_side'>
                        <h1>Enroll For</h1>
                        <p>{props?.program}</p>
                    </div>

                    <div className='right_side'>
                        <div className="join_btn_div">
                            <Button
                              onClick={() => {
                                setTimeout(() => {
                                    setModalShow(false) 
                                }, 3000);
                                setModalShow(true) 
                            }}
                                className="join_btn"
                                type="submit"
                            >
                                Join Now
                            </Button>
                        </div>
                    </div>
                </div>

                <div className='footer_text'>
                    <p>For joining Please WhatsApp/call : {props?.contact_no ? props?.contact_no : "+91 9871655591"}</p>
                </div>
            </div>

            <ContactLottieModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                contact_no={props?.contact_no}
            />
        </>
    )
}

export default EnrollSection
