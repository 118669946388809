import React, { useState } from 'react'
import "./ContactUs.css";
import { faEnvelope, faMapLocationDot, faPhoneVolume, faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Form, InputGroup } from 'react-bootstrap';
import MessageSentModal from '../CommonModal/MessageSentModal';
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Errors } from '../../utils/errors';
import { RegxExpression } from '../../utils/common';
import { ContactUsForm } from '../../utils/apis/contactus/contactus';

const ContactUs = () => {

    const [modalShow, setModalShow] = useState(false)
    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm();


    const onSubmit = async (data) => {

        const formdata = getValues()

        const res = await ContactUsForm(formdata);
        if (res?.success) {
            setTimeout(() => {
                setModalShow(false)
            }, 3000);
            setModalShow(true)
        }

    };

    return (
        <section className="ContactUs">
            <div className='container-fluid p-0'>
                <div className='map_holder'>
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe
                                className="gmap_iframe"
                                src="https://maps.google.com/maps?width=600&amp;height=600&amp;hl=en&amp;q=pune&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                                allowFullScreen=""
                                loading="lazy"
                            ></iframe>
                            <a href="https://pdflist.com/" alt="pdflist.com"></a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container">

                <div className='heading_holder'>
                    <div className='heading'>
                        <h1>Contact Us</h1>
                    </div>
                    <div className='sub_heading'>
                        <h1>Contact Us</h1>
                    </div>
                </div>



                <div className='main_div_holder'>

                    <div className='row'>
                        <div className='col-lg-5 col-md-7 col-sm-9 col-12'>
                            <div className='top_card'>
                                <div className='row'>
                                    <div className='col-lg-9 col-md-9 col-sm-9 col-9'>
                                        <div className='address_holder'>
                                            <h3>To Be Blissful</h3>
                                            <div className='address_text'>
                                                <p>K 801 , Ganga Ashiyana, Thergaon, </p>
                                                <p>Pin Code: 411033 </p>
                                                <p>Near Aditya Birla Hospital, Pune</p>
                                            </div>
                                            <div className='star_holder'>
                                                <p><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStar} /><FontAwesomeIcon icon={faStarHalf} /></p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-lg-3 col-md-3 col-sm-3 col-3'>
                                        <div className="img_holder">
                                            <img
                                                className="logo_img"
                                                src={
                                                    process.env.PUBLIC_URL + "/Assets/Images/ContactUs/direction.png"
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='contact_form_holder'>
                        <div className='row'>
                            <div className='col-lg-5 col-md-5 col-sm-12 '>
                                <div className='left_card'>
                                    <h3>Get in touch</h3>
                                    <div className='data_holder'>
                                        <div className='icon_holder'>
                                            <p><FontAwesomeIcon icon={faPhoneVolume} /></p>
                                        </div>
                                        <div className='text_holder'>
                                            <h4>+91 9871655591</h4>
                                        </div>
                                    </div>

                                    <div className='data_holder'>
                                        <div className='icon_holder'>
                                            <p><FontAwesomeIcon icon={faEnvelope} /></p>
                                        </div>
                                        <div className='text_holder'>
                                            <h4>contact@tobeblissful.in</h4>
                                        </div>
                                    </div>

                                    <div className='data_holder'>
                                        <div className='icon_holder'>
                                            <p><FontAwesomeIcon icon={faMapLocationDot} /></p>
                                        </div>
                                        <div className='text_holder'>
                                            <h4>K 801 , Ganga Ashiyana,5 Thergaon, Pin Code: 411033 Near Aditya Birla Hospital, Pune</h4>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div className='col-lg-7 col-md-7 col-sm-12'>
                                <div className='right_card'>
                                    <h4>Send us a Message</h4>
                                    <div className='form_holder'>
                                        <div className='form_div'>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                                <div className="main-form-section mt-2">

                                                    <div className="input-div">
                                                        <div className='row'>
                                                            <div className='col-lg-12'>
                                                                <Form.Group>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Enter Your Full Name"
                                                                            className={classNames("", {
                                                                                "is-invalid": errors?.name,
                                                                                "is-valid": !errors?.name && getValues("name"),
                                                                            })}
                                                                            {...register("full_name", {
                                                                                required: "Name is required",
                                                                            })}
                                                                        />

                                                                    </InputGroup>
                                                                    {errors.full_name && <span className="text-danger">{errors.full_name.message}</span>}
                                                                </Form.Group>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="input-div">
                                                        <div className='row'>
                                                            <div className='col-lg-6'>
                                                                <Form.Group>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Enter Contact Number"
                                                                            {...register("contact_number", {
                                                                                required: "Contact number is required",
                                                                                validate: {
                                                                                    isTenDigits: (value) =>
                                                                                        value && value.length === 10 || "Contact number must be 10 digits",
                                                                                    isNumeric: (value) =>
                                                                                        /^\d+$/.test(value) || "Contact number must be numeric"
                                                                                }
                                                                            })}
                                                                            className={classNames("", {
                                                                                "is-invalid": errors?.contact_no,
                                                                                "is-valid": !errors?.contact_no && getValues("contact_number")?.length === 10,
                                                                            })}
                                                                            // onKeyDown={(event) => {
                                                                            //     const { key } = event;
                                                                            //     if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab") {
                                                                            //         event.preventDefault();
                                                                            //     }
                                                                            //     if (event.target.value?.length >= 10 && key !== "Backspace" && key !== "Tab") {
                                                                            //         event.preventDefault();
                                                                            //     }
                                                                            // }}
                                                                        />
                                                                    </InputGroup>
                                                            {errors.contact_number && <span className="text-danger">{errors.contact_number.message}</span>}

                                                                </Form.Group>
                                                            </div>

                                                            <div className='col-lg-6'>
                                                                <Form.Group>
                                                                    <InputGroup>
                                                                        <Form.Control
                                                                            type="text"
                                                                            placeholder="Enter Email Id"
                                                                            {...register("email", {
                                                                                required: Errors.email,
                                                                                pattern: {
                                                                                    value: RegxExpression.email,
                                                                                    message: Errors.email_invalid,
                                                                                },
                                                                            })}
                                                                            className={classNames("", {
                                                                                "is-invalid": errors?.email,
                                                                            })}
                                                                        />
                                                                    </InputGroup>
                                                            {errors.email && <span className="text-danger">{errors.email.message}</span>}

                                                                </Form.Group>
                                                            </div>
                                                        </div>

                                                    </div>

                                                    <div className="input-div">
                                                        <Form.Group>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    as="textarea" // Use "as" prop to render a textarea
                                                                    placeholder="Type here...."
                                                                    rows={4} // Optional: Set the number of visible rows

                                                                    {...register("message", {
                                                                        required: "Message is required", // Validation rule for required field
                                                                    })}
                                                                />
                                                            </InputGroup>
                                                            {errors.message && <span className="text-danger">{errors.message.message}</span>}

                                                        </Form.Group>
                                                    </div>

                                                    <div className="btn_div">
                                                        <Button
                                                           
                                                            className="submit_btn"
                                                            type="submit"
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <MessageSentModal
                    message="Message Send Successfully!"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                />
            </div>
        </section>
    )
}

export default ContactUs
