import {
    getData,
    postData,
  } from "../../api";

export const ContactUsForm = async (data) => {
    try {
      const res = await postData(
        `/contactusform`,data
      );
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };
