import React, { useRef, useState } from "react";
import "./Banner.css";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BannerTypeOne from "../../CommonComponents/HomeBanner/BannerTypeOne";
import BannerTypeTwo from "../../CommonComponents/HomeBanner/BannerTypeTwo";

function Banner() {

  const swiperRef = useRef(null);
  const [swiperInstance, setSwiperInstance] = useState(null);
  const [swiperInstancenew, setSwiperInstancenew] = useState(null);

  const handleMainSliderClick = (direction) => {

    console.log("swiperInstance", swiperInstance, direction)

    if (swiperInstance) {
      if (direction === "prev") {
        swiperInstance.slidePrev();
      } else if (direction === "next") {
        swiperInstance.slideNext();
      }
    }
  };

  const handleSliderClick = (direction) => {
    if (swiperInstancenew) {
      if (direction === "prev") {
        swiperInstancenew.slidePrev();
      } else if (direction === "next") {
        swiperInstancenew.slideNext();
      }
    }
  };


  const handleClickSlide = (index) => {
    console.log(swiperRef.current.swiper);
    if (swiperRef.current !== null) {
      swiperRef.current.swiper.slideTo(index);
    }
  };

  return (
    <section className="home-banner">
      <div className="container-fluid ">
        <div className="row p-0">
          <div className="col-md-12 p-0">
            <Swiper
              ref={swiperRef}
              spaceBetween={20}
              slidesPerView={1}
              onSlideChange={() => console.log("slide change")}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              autoplay={{
                delay: 2000, // 3 seconds delay between slides
                disableOnInteraction: false,
              }}
              navigation={true} // Enable navigation buttons
              modules={[Pagination, Navigation]} // Add Navigation here
              className="mySwiper pb-5"
              onSwiper={(swiper) => setSwiperInstance(swiper)}
            >
              <SwiperSlide>

              <BannerTypeOne src="/Assets/Images/HomeBanner/img1.png" redirectTo="/our-services"  btnText={" Explore More"}/>

              </SwiperSlide>

              <SwiperSlide>
              <BannerTypeOne src="/Assets/Images/HomeBanner/img3.png" redirectTo="/our-services/swaryoga"  btnText={" Explore More"}/>
              </SwiperSlide>

              <SwiperSlide>
              <BannerTypeTwo src="/Assets/Images/HomeBanner/img2.png" redirectTo="/our-services/onilne-pranayam" btnText={" Explore More"}/>
              </SwiperSlide>

              
              <SwiperSlide>
              <BannerTypeTwo src="/Assets/Images/HomeBanner/img4.png" redirectTo="/our-services/advance-pranayam" btnText={" Explore More"} />
              </SwiperSlide>


            </Swiper>

            <div className="btn_backgound_holder">
              <div className="silder-btn">
                <div className="back_btn_holder">
                  <div
                    className="back-btn"
                    onClick={() => handleMainSliderClick("prev")}
                  >
                    <p> <FontAwesomeIcon icon={faAngleLeft} /></p>
                  </div>
                </div>
                <div className="next_btn_holder">
                  <div
                    className="next-btn"
                    onClick={() => handleMainSliderClick("next")}
                  >
                    <p> <FontAwesomeIcon icon={faAngleRight} /></p>
                  </div>
                </div>

              </div>
            </div>


          </div>
        </div>
      </div>

    </section>
  );
}

export default Banner;
