import React from "react";
import "./Terms_Conditions_Content.css";

function Terms_Conditions_Content() {
  return (
    <>
      <section className="Terms_Conditions_Content">
        <div className="container">
          <div className="Terms_Conditions_Content_holder">
            <ol>
              <li>
                <div className="text-holder">
                  <h3>Definitions</h3>
                  <p>
                    In these Terms and Conditions, the following terms shall have the meanings set out below:
                  </p>
                  <p>“Company”, “We”, “Us”: Refers to [Insurance Company Name].</p>
                  <p>“Policyholder”, “You”, “Your”: Refers to any individual or entity purchasing insurance or using our services.</p>
                  <p>“Policy”: Refers to any insurance coverage or product provided by [Insurance Company Name].</p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Acceptance of Terms</h3>
                  <p>
                    By purchasing an insurance policy, using our services, or accessing our website, you acknowledge that you have read, understood, and agree to comply with these Terms and Conditions. If you do not agree to these terms, you should not use our services.
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Eligibility for Services</h3>
                  <p>To be eligible for our insurance services, you must:</p>
                  <p>Be at least 18 years of age (or the legal age of majority in your jurisdiction).</p>
                  <p>Be capable of entering into a legally binding contract.</p>
                  <p>Provide accurate, complete, and up-to-date information during the application process.</p>
                  <p>Meet any specific requirements for the insurance policy for which you are applying.</p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Insurance Policies</h3>
                  <p>All insurance policies offered by [Insurance Company Name] are subject to the terms, conditions, and limitations outlined in the specific policy documents. These may include, but are not limited to:</p>
                  <p>Policy Coverage: Coverage specifics, including covered events, exclusions, and limitations.</p>
                  <p>Premiums: Payment terms, amounts, and schedules.</p>
                  <p>Claims Process: The process and documentation required to submit a claim.</p>
                  <p>Policy Term: The duration and renewal terms of the policy.</p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Premium Payments</h3>
                  <p>Policyholders are responsible for timely payment of premiums. Failure to pay your premium on time may result in the cancellation of your policy. The following terms apply:</p>
                  <p>Payment Methods: We accept payment through various methods such as credit cards, direct bank transfers, or other accepted forms.</p>
                  <p>Late Payments: If payment is not made within the grace period outlined in your policy, we reserve the right to cancel or suspend coverage.</p>
                  <p>Refunds: Refunds, if applicable, will be processed in accordance with the specific terms outlined in your policy.</p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Claims and Benefits</h3>
                  <p>Policyholders are entitled to file claims as per the conditions outlined in their insurance policy. To ensure the efficient processing of claims, you must:</p>
                  <p>Provide accurate and complete information when submitting a claim.</p>
                  <p>Submit all required documentation within the specified time frame.</p>
                  <p>Cooperate with any investigations or requests for additional information.</p>
                  <p>Understand that false or fraudulent claims may lead to denial of coverage or legal action.</p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Cancellation and Termination</h3>
                  <p>Either party may terminate the insurance policy in accordance with the terms outlined in the policy. Reasons for cancellation may include:</p>
                  <p>Non-payment of premiums.</p>
                  <p>Misrepresentation or fraud in obtaining coverage.</p>
                  <p>Violation of the terms of the policy.</p>
                  <p>If you wish to cancel your policy, you may do so by providing written notice to [Insurance Company Name].</p>
                  <p>Any refunds or penalties associated with cancellations will be governed by the terms of the specific policy.</p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Limitation of Liability</h3>
                  <p>To the maximum extent permitted by law, [Insurance Company Name] shall not be liable for any indirect, incidental, or consequential damages arising out of or in connection with the use of our services, purchase of insurance policies, or any other action under these Terms and Conditions.</p>
                  <p>Our total liability to you for any damages, claims, or causes of action, regardless of the form of the action, shall not exceed the total amount paid by you for the insurance policy in question.</p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Data Protection</h3>
                  <p>Your personal information is protected in accordance with our Privacy Policy. We take reasonable steps to protect your data, but we cannot guarantee absolute security. By agreeing to these terms, you consent to the collection and use of your data as outlined in our Privacy Policy.</p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Third-Party Services</h3>
                  <p>We may use third-party service providers in connection with our services, including but not limited to payment processors and claims administrators. We are not responsible for the actions, omissions, or privacy practices of these third parties. Any third-party links or references on our website or materials are provided for your convenience only.</p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Amendments</h3>
                  <p>We reserve the right to modify these Terms and Conditions at any time. Changes will be effective immediately upon posting on our website or providing notice to you. Your continued use of our services after changes are made constitutes your acceptance of the updated Terms and Conditions.</p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Governing Law</h3>
                  <p>These Terms and Conditions shall be governed by and construed in accordance with the laws of [Jurisdiction]. Any disputes arising from or relating to these terms, your policy, or the services provided will be resolved through arbitration or in the courts of [Jurisdiction].</p>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
}

export default Terms_Conditions_Content;
