import React, { useState } from 'react'
import "./ContactUs.css";
import { Button, Form, InputGroup } from 'react-bootstrap';
import MessageSentModal from '../../CommonModal/MessageSentModal';
import { useForm } from "react-hook-form";
import classNames from "classnames";
import { Errors } from '../../../utils/errors';
import { RegxExpression } from '../../../utils/common';
import { ContactUsForm } from '../../../utils/apis/contactus/contactus';

const ContactUs = () => {

    const [modalShow, setModalShow] = useState(false)

    const {
        register,
        control,
        handleSubmit,
        watch,
        getValues,
        reset,
        formState: { errors },
    } = useForm();

    console.log("errors", errors)


    const onSubmit = async (data) => {

        const formdata = getValues()

        const res = await ContactUsForm(formdata);
        if (res?.success) {
            setTimeout(() => {
                setModalShow(false)
            }, 2000);
            setModalShow(true)
        }

    };


    return (
        <section className="ContactUsHome">
            <div className="container">
                <div className='main_div'>
                    <div className="row">
                        <div className='col-xl-7 col-lg-6 col-md-12 col-sm-12 col-12'>
                            <div className='img_holder'>
                                <img
                                    className="logo_img"
                                    src={
                                        process.env.PUBLIC_URL + "/Assets/Images/Home/ContactUs/bg.png"
                                    }
                                />
                            </div>
                        </div>
                        <div className='col-xl-5 col-lg-6 col-md-12 col-sm-12 col-12'>
                            <div className='main_contact_div'>
                                <h3>Contact Us</h3>
                                <div className='form_div'>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="main-form-section mt-2">
                                            <div className="input-div">
                                                <Form.Group>
                                                    <Form.Label className="emailtxt">
                                                        Full Name
                                                    </Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            type="text"
                                                            placeholder="Enter Your Full Name"
                                                            className={classNames("", {
                                                                "is-invalid": errors?.name,
                                                                "is-valid": !errors?.name && getValues("name"),
                                                            })}
                                                            {...register("full_name", {
                                                                required: "Name is required",
                                                            })}
                                                        />

                                                    </InputGroup>
                                                    {errors.full_name && <span className="text-danger">{errors.full_name.message}</span>}
                                                </Form.Group>
                                            </div>

                                            <div className="input-div">
                                                <div className='row'>
                                                    <div className='col-lg-6'>
                                                        <Form.Group>
                                                            <Form.Label className="emailtxt">
                                                                Contact Number
                                                            </Form.Label>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter Contact Number"
                                                                    {...register("contact_number", {
                                                                        required: "Contact number is required",
                                                                        validate: {
                                                                            isTenDigits: (value) =>
                                                                                value && value.length === 10 || "Contact number must be 10 digits",
                                                                            isNumeric: (value) =>
                                                                                /^\d+$/.test(value) || "Contact number must be numeric"
                                                                        }
                                                                    })}
                                                                    className={classNames("", {
                                                                        "is-invalid": errors?.contact_no,
                                                                        "is-valid": !errors?.contact_no && getValues("contact_number")?.length === 10,
                                                                    })}
                                                                    onKeyDown={(event) => {
                                                                        const { key } = event;
                                                                        if (!/[0-9]/.test(key) && key !== "Backspace" && key !== "Tab") {
                                                                            event.preventDefault();
                                                                        }
                                                                        if (event.target.value?.length >= 10 && key !== "Backspace" && key !== "Tab") {
                                                                            event.preventDefault();
                                                                        }
                                                                    }}
                                                                />
                                                            </InputGroup>
                                                            {errors.contact_number && <span className="text-danger">{errors.contact_number.message}</span>}
                                                        </Form.Group>
                                                    </div>

                                                    <div className='col-lg-6'>
                                                        <Form.Group>
                                                            <Form.Label className="emailtxt">
                                                                Email Id
                                                            </Form.Label>
                                                            <InputGroup>
                                                                <Form.Control
                                                                    type="text"
                                                                    placeholder="Enter Email Id"
                                                                    {...register("email", {
                                                                        required: Errors.email,
                                                                        pattern: {
                                                                            value: RegxExpression.email,
                                                                            message: Errors.email_invalid,
                                                                        },
                                                                    })}
                                                                    className={classNames("", {
                                                                        "is-invalid": errors?.email,
                                                                    })}
                                                                />
                                                            </InputGroup>
                                                            {errors.email && <span className="text-danger">{errors.email.message}</span>}

                                                        </Form.Group>
                                                    </div>
                                                </div>

                                            </div>

                                            <div className="input-div">
                                                <Form.Group>
                                                    <Form.Label className="emailtxt">
                                                        Message
                                                    </Form.Label>
                                                    <InputGroup>
                                                        <Form.Control
                                                            as="textarea" // Use "as" prop to render a textarea
                                                            placeholder="Type here...."
                                                            rows={4} // Optional: Set the number of visible rows

                                                            {...register("message", {
                                                                required: "Message is required", // Validation rule for required field
                                                            })}
                                                        />
                                                    </InputGroup>
                                                    {errors?.message && <span className="text-danger">{errors?.message.message}</span>}

                                                </Form.Group>
                                            </div>

                                            <div className="btn-div">
                                                <Button
                                                    className="submit-btn"
                                                    type="submit"

                                                >
                                                    Send Message
                                                </Button>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <MessageSentModal
                message="Message Send Successfully!"
                show={modalShow}
                onHide={() => setModalShow(false)}
            />
        </section>
    )
}

export default ContactUs
