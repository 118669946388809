import {
    getData,
    postData,
  } from "../../api";

export const Getblogs = async (user_id) => {
    try {
      const res = await getData(
        `/getblogs`
      );
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };


  export const SingleGetblogs = async (id) => {
    try {
      const res = await getData(`/getsingleblog/${id}`); // Pass the ID in the URL
      return res;
    } catch (error) {
      console.log(error);
    }
  };
  

  export const GetBlogBanner = async (user_id) => {
    try {
      const res = await getData(
        `/getblogbanner`
      );
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };


  export const GetRecentBlogs = async (user_id) => {
    try {
      const res = await getData(
        `/getrecentblogs`
      );
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };