import React from 'react'

const Description = (props) => {
    return (
        <div className='description'>
            <p className='para1'>{props?.para1} </p>

            <p className='para2'>{props?.para2} </p>
        </div>
    )
}

export default Description
