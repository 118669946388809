import React from 'react'
import "./JoyfullInvestment.css";


const JoyfullInvestment = () => {
    return (
        <section className="JoyfullInvestment">
            <div className="container">
                <div className='heading'>
                    <h3>A joyful <span>Investment</span> in your Body,</h3>
                    <h3>Mind and Spirit</h3>
                </div>

                <div className='content_holder'>
                    <div className="row">
                        <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                            <div className='icon_holder'>
                                <div className="logo_holder">
                                    <img
                                        className="logo_img"
                                        src={
                                            process.env.PUBLIC_URL + "/Assets/Images/Home/JoyInvestment/img1.png"
                                        }
                                    />

                                    <img
                                        className="logo_img red_icon"
                                        src={
                                            process.env.PUBLIC_URL + "/Assets/Images/Home/JoyInvestment/hover_img1.png"
                                        }
                                    />
                                </div>
                            </div>
                            <div className='heading'>
                                <h4>Holistic Wellness</h4>
                            </div>
                            <div className='content'>
                                <p>We integrate yoga, Dhyan, pranayama, & meditation to enhance your physical, mental, & spiritual well-being, fostering harmony in your life</p>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                            <div className='icon_holder'>
                                <div className="logo_holder">
                                    <img
                                        className="logo_img"
                                        src={
                                            process.env.PUBLIC_URL + "/Assets/Images/Home/JoyInvestment/img2.png"
                                        }
                                    />
                                    <img
                                        className="logo_img red_icon"
                                        src={
                                            process.env.PUBLIC_URL + "/Assets/Images/Home/JoyInvestment/hover_img2.png"
                                        }
                                    />
                                </div>
                            </div>
                            <div className='heading'>
                                <h4>Expert Guidance</h4>
                            </div>
                            <div className='content'>
                                <p>Our experienced instructors offer personalized attention, helping you progress at your own pace with tailored practices</p>
                            </div>
                        </div>


                        <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                            <div className='icon_holder'>
                                <div className="logo_holder">
                                    <img
                                        className="logo_img"
                                        src={
                                            process.env.PUBLIC_URL + "/Assets/Images/Home/JoyInvestment/img3.png"
                                        }
                                    />
                                    <img
                                        className="logo_img red_icon"
                                        src={
                                            process.env.PUBLIC_URL + "/Assets/Images/Home/JoyInvestment/hover_img3.png"
                                        }
                                    />
                                </div>
                            </div>
                            <div className='heading'>
                                <h4>Diverse Offerings</h4>
                            </div>
                            <div className='content'>
                                <p>We provide a variety of classes & workshops for all skill levels, allowing you to explore & deepen your practice in a supportive setting</p>
                            </div>
                        </div>

                        <div className='col-lg-3 col-md-6 col-sm-6 col-12'>
                            <div className='icon_holder'>
                                <div className="logo_holder">
                                    <img
                                        className="logo_img"
                                        src={
                                            process.env.PUBLIC_URL + "/Assets/Images/Home/JoyInvestment/img4.png"
                                        }
                                    />
                                    <img
                                        className="logo_img red_icon"
                                        src={
                                            process.env.PUBLIC_URL + "/Assets/Images/Home/JoyInvestment/hover_img4.png"
                                        }
                                    />
                                </div>
                            </div>
                            <div className='heading'>
                                <h4>Community Connection</h4>
                            </div>
                            <div className='content'>
                                <p>Join a vibrant community of wellness enthusiasts, fostering connections that enhance your journey of self-discovery and inner peace.</p>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </section>
    )
}

export default JoyfullInvestment
