import { faCalendar } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'

const BatchSection = (props) => {
    return (
        <div className='activity_holder'>
            <div className='row'>
                <div className='col-lg-6 col-md-6'>
                    <div className='left_side_div'>
                        <p className='session_heading'>{props?.title}</p>



                        <div className='session_div_holder'>


                            {props?.activities?.map((item,index) => {
                                return (
                                    <div className='session_div'>
                                        <div className='number_div'>
                                            <p>{`0${index+1}`} </p>
                                        </div>

                                        <div className='session_text_holder'>
                                            <p>{item}</p>
                                        </div>

                                    </div>
                                )
                            })}
                        </div>


                    </div>
                </div>

                <div className='col-lg-6 col-md-6'>
                    <div className='right_side_div'>
                        <div className='batch_div'>
                            <div >
                                <p className='heading'> <FontAwesomeIcon icon={faCalendar} /><span>Timings:</span></p>

                                <p className='sub_heading'>Morning 6 am to 7 am | Monday to Sunday</p>
                            </div>

                            <div >
                                <p className='heading'> <FontAwesomeIcon icon={faCalendar} /><span>Batch:</span></p>

                                <p className='sub_heading'>New batches on 1st date of each month</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BatchSection
