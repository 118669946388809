import React from 'react'
import "./SwaryogaHeading.css";


const SwaryogaHeading = (props) => {

    return (
        <section className='SwaryogaHeading'>

            {props?.title_heading?.map((item, index) => {
                return (
                    <div className='first_heading_holder'>
                        <p><span className='type'>{item?.title} </span>{item?.descn}</p>
                    </div>
                )
            })}


        </section>
    )
}

export default SwaryogaHeading
