
import React, { useContext } from "react";
import "./Blog_inner_Banner.css";
import { Context } from "../../../utils/context";

function Blog_inner_Banner({ banner, subtitle, title }) {

  const { IMG_URL } = useContext(Context);

  const isVideo = (file) => {
    if (!file) return false; // If file is not defined, return false

    const videoExtensions = ["mp4", "webm", "ogg"];
    const fileExtension = file.split(".").pop().toLowerCase();
    return videoExtensions.includes(fileExtension);
  };

  return (
    <>
      <section className="Blog_inner_Banner">
        <div className="container">
          <div className="card-img-holder">
            <div className="img-holder">
              {banner && isVideo(banner) ? (
                <video
                  className="Blog_card_img"
                  controls
                  autoPlay
                  muted
                  loop
                >
                  <source
                    src={`${IMG_URL}${banner}`}
                    type={`video/${banner.split(".").pop().toLowerCase()}`}
                  />
                  Your browser does not support HTML5 video.
                </video>
              ) : (
                banner && (
                  <img
                    src={`${IMG_URL}${banner}`}
                    className="Blog_card_img"
                    alt="Blog_card_img"
                  />
                )
              )}
            </div>
            <div className="overlay-holder"></div>

            <div className="text-holder">
              <h3>
                {title}<br />
                {subtitle}
              </h3>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog_inner_Banner;
