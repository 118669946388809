import React from "react";
import "./App.css";
import "./index.css";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

import "./components/CommonCss/CommonCss.css"

import { Route, Routes } from "react-router-dom";
import Header from "./components/header/Header";
import Footer from "./components/footer/Footer";
import Home from "./components/home/Home";
import ContactUs from "./components/ContactUs/ContactUs";
import AboutUs from "./components/AboutUs/AboutUs";
import Blogs from "./components/Blogs/Blogs";
import OurServices from "./components/OurServices/OurServices";
import Privacy_Policy from "./components/Privacy_Policy/Privacy_Policy";
import Terms_Conditions from "./components/Terms_Conditions/Terms_Conditions";
import Blog_inner from "./components/Blogs/Blog_inner/Blog_inner";
import OnlinePranayam from "./components/OurServices/ServiceTypes/OnlinePranayam/OnlinePranayam";
import AdvancePranayam from "./components/OurServices/ServiceTypes/AdvancePranayam/AdvancePranayam";
import SwarYoga from "./components/OurServices/ServiceTypes/SwarYoga/SwarYoga";
import Bell from "./components/home/Bell/Bell";
import ScrollToTop from "./components/ScrollTotop/ScrollTotop";
import AppContext from "./utils/context";

function App() {
  return (
    <>
      <AppContext>
        <ScrollToTop />
        <Header />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blogs-inner/:id" element={<Blog_inner />} />
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/our-services/onilne-pranayam" element={<OnlinePranayam />} />
          <Route path="/our-services/advance-pranayam" element={<AdvancePranayam />} />
          <Route path="/our-services/swaryoga" element={<SwarYoga />} />
          <Route path="/privacy-policy" element={<Privacy_Policy />} />
          <Route path="/terms-conditions" element={<Terms_Conditions />} />
        </Routes>
       
        <Footer />
      </AppContext>
    </>
  );
}

export default App;
