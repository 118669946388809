import React from "react";
import Privacy_Policy_Content from "./Privacy_Policy_Content/Privacy_Policy_Content";
import Common_banner from "../CommonComponents/Common_banner/Common_banner";

function Privacy_Policy() {
  return (
    <>
      <Common_banner
        imgSrc={"/Assets/Images/Privacy_Policy/Privacy-Policy-banner.png"}
        mainHeading={"Privacy Policy"}
        heading={"Privacy Policy"}
      />

      <Privacy_Policy_Content />

    </>
  );
}

export default Privacy_Policy;
