import React from "react";

function Privacy_Policy_Content() {
  return (
    <>
      <section className="Terms_Conditions_Content">
        <div className="container">
          <div className="Terms_Conditions_Content_holder">
            <div className="text-holder">
              <p>
                At To Be Blissful we value your privacy and are committed to
                protecting your personal information. This Privacy Policy
                outlines how we collect, use, and safeguard your data when you
                visit or use our website, particularly about our online
                pranayama and yoga classes.
              </p>
            </div>
            <ol>
              <li>
                <div className="text-holder">
                  <h3>Information We Collect</h3>
                  <p> We may collect the following types of information:</p>
                  <p>
                    - <strong>Personal Information:</strong> Name, email
                    address, phone number, billing details, and any other
                    information you provide when signing up for classes,
                    newsletters, or any other services.
                  </p>
                  <p>
                    - <strong>Payment Information:</strong> We collect payment
                    details for processing transactions, which third-party
                    payment processors securely handle.
                  </p>
                  <p>
                    - <strong>Usage Data:</strong> Information about how you use
                    our website, including IP address, browser type, device
                    information, and interaction data (e.g., pages viewed, links
                    clicked).
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>How We Use Your Information</h3>
                  <p>We use the collected data for the following purposes:</p>
                  <p>
                    - To provide and improve our online pranayama and yoga
                    classes.
                  </p>
                  <p>- To process transactions and manage your account.</p>
                  <p>
                    - To send updates, newsletters, and promotional materials
                    related to our services (with your consent).
                  </p>
                  <p>- To respond to inquiries and provide customer support.</p>
                  <p>
                    - To analyze and improve website performance and user
                    experience.
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Sharing of Information</h3>
                  <p>
                    We do not sell, rent, or share your personal information
                    with third parties, except in the following cases:
                  </p>
                  <p>
                    - <strong>Service Providers:</strong> We may share data with
                    trusted third-party providers (e.g., payment processors,
                    email service providers) to facilitate services on our
                    behalf.
                  </p>
                  <p>
                    - <strong>Legal Requirements:</strong> We may disclose your
                    information if required to do so by law or in response to
                    valid legal requests from government authorities.
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Data Security</h3>
                  <p>
                    We take appropriate measures to ensure the security of your
                    personal information. This includes encryption, secure
                    servers, and access control mechanisms to prevent
                    unauthorized access, disclosure, or modification of your
                    data.
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Cookies and Tracking Technologies</h3>
                  <p>
                    Our website uses cookies and similar tracking technologies
                    to enhance user experience, analyze website traffic, and
                    personalize content. You can control cookie preferences
                    through your browser settings.
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Your Rights</h3>
                  <p>
                    You have the following rights concerning your personal data:
                  </p>
                  <p>
                    - <strong>Access:</strong> You may request access to the
                    information we hold about you.
                  </p>
                  <p>
                    - <strong>Correction:</strong> You can request corrections
                    to inaccurate or incomplete data.
                  </p>
                  <p>
                    - <strong>Deletion:</strong> You may request the deletion of
                    your personal data, subject to legal and business
                    record-keeping obligations.
                  </p>
                  <p>
                    - <strong>Opt-Out:</strong> You can opt out of receiving
                    marketing communications at any time by using the
                    unsubscribe link in our emails.
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Third-Party Links</h3>
                  <p>
                    Our website may contain links to third-party websites. We
                    are not responsible for the privacy practices of these
                    websites and encourage you to review their privacy policies.
                  </p>
                </div>
              </li>

              <li>
                <div className="text-holder">
                  <h3>Changes to This Privacy Policy</h3>
                  <p>
                    We may update this Privacy Policy periodically. Any changes
                    will be posted on this page with an updated effective date.
                  </p>
                </div>
              </li>
            </ol>
          </div>
        </div>
      </section>
    </>
  );
}

export default Privacy_Policy_Content;
