import React, { useContext, useEffect, useState } from "react";
import Blog_inner_content from "./Blog_inner_content/Blog_inner_content";
import Breadcrumbs_Holder from "../../CommonComponents/Breadcrumbs_Holder/Breadcrumbs_Holder";
import Blog_inner_Banner from "../../CommonComponents/Blog_inner_Banner/Blog_inner_Banner";
import { GetBlogsInnerApi } from "../../../utils/apis/blogs/blogsinnerpage/blogsinnerapi";
import { SingleGetblogs } from "../../../utils/apis/blogs/blogs";
import { useParams } from "react-router-dom";

 
function Blog_inner() {


  const {id} = useParams()

  console.log("useParams",id)

  const [blogsInnerData,setBlogsInnerData] = useState([])


  const fetchBlog = async (id) => {
    try {
      const res = await SingleGetblogs(id);
      if(res?.success){
        setBlogsInnerData(res?.data)
      }
    } catch (error) {
      console.error('Error fetching blog:', error);
    }
  };

  console.log("blogsInnerData",blogsInnerData)


  useEffect(()=>{
    fetchBlog(id)
  },[id])


  const breadcrumbs = [
    { label: "Blogs", link: "/blogs", active: false },
    {
      label: `${blogsInnerData?.title1}: ${blogsInnerData?.title2}.......`,
      link: "",
      active: true,
    },
  ];
  return (
    <>
      <Breadcrumbs_Holder breadcrumbs={breadcrumbs} />

      <Blog_inner_Banner banner={blogsInnerData?.image}  subtitle={blogsInnerData?.title2}  title={blogsInnerData?.title1}  />

      <Blog_inner_content description={blogsInnerData?.description}/>
    </>
  );
}

export default Blog_inner;
