import {
    getData,
    postData,
  } from "../../api";

export const GetReviews = async (user_id) => {
    try {
      const res = await getData(
        `/getreviews`
      );
  
      return res;
    } catch (error) {
      console.log(error);
    }
  };