import { createContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

import {
  getData,
  postData,
  putData,
  deleteData,
} from "./api";

import {
  Select2Data,
  RegxExpression,
} from "./common";

export const Context = createContext();

const AppContext = ({ children }) => {
  const { pathname } = useLocation();


  const IMG_URL = "http://blissful-admin.profcymabackend.com/";
  // const IMG_URL = "http://localhost/bliss_admin/";


 

  return (
    <Context.Provider
      value={{
        IMG_URL,
        getData,
        postData,
        putData,
        deleteData,      
        Select2Data,      
        RegxExpression,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
