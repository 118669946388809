import React from 'react'
import "./ActionSection.css";

const ActionSection = () => {
    return (
        <section className='ActionSection'>
            <div className='heading'>
                <h3>योग, प्राणायाम, शुद्धी क्रियाऐं, ध्यान, तत्व साधना</h3>
            </div>

            <div className='content_section'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <ul>
                            <li><p>शुद्धी क्रियाएं - <span>Alternate Nostril KapalBhati</span> - जल नेति, त्राटक</p></li>
                            <li><p>सरल योगाभ्यास - <span>Yoga For Senior Citizens </span></p></li>
                            <li><p>आसन, प्राणायाम, ध्यान</p> </li>
                            <li><p>बंध, मुद्रा, खेचरी मुद्रा,  षणमुखी मुद्रा, शाम्भवी मुद्रा का अभ्यास</p> </li>
                            <li><p><span>Yoga for Relaxation / Meditation </span>शवासन</p>  </li>
                        </ul>
                    </div>
                    <div className='col-lg-6'>
                        <ul>
                            <li><p>ओंकार ध्यान </p></li>
                            <li><p><span>MSRT</span> ध्यान की विधि</p></li>
                            <li><p>साक्षी ध्यान</p> </li>
                            <li><p>शवान्स ध्यान आनापाना सति </p> </li>
                            <li><p>कुंडलिनी चक्र ध्यान का विशेष अभ्यास संकल्प से जीवन परिवर्तन </p> </li>
                        </ul>

                    </div>
                </div>
            </div>
            <div className='divider'>
                <hr />
            </div>



            <div className='heading'>
                <h3>शिव स्वरोदय ग्रंथ और स्वामी सत्यानंद जी सरस्वती द्वारा लिखित पुस्तक “ स्वर योग “ पर आधारित Syllabus</h3>
            </div>

            <div className='content_section'>
                <div className='row'>
                    <div className='col-lg-6'>
                        <ul>
                            <li><p>ग्रंथ परिचय, ग्रंथ प्रवेश</p></li>
                            <li><p>स्वर- कौन सा स्वर चल रहा है- जानना</p></li>
                            <li><p>स्वर कैसे बदलें बदलने के तरीके</p> </li>
                            <li><p>शिव स्वरोदय और तत्व ज्ञान</p> </li>
                            <li><p>कौन से स्वर में कौन सा कार्य करे ?</p>  </li>
                            <li><p>स्वर के अनुसार स्वस्थ जीवन</p>  </li>
                        </ul>
                    </div>
                    <div className='col-lg-6'>
                        <ul>
                            <li><p>तत्व ज्ञान- तत्व को कैसे पहचाने ?</p></li>
                            <li><p>मनोरथ कैसे सिद्ध हो, हमारा जीवन कैसे सफल बने ? </p></li>
                            <li><p>विभिन्न मुद्राएं, प्राणायाम और बंध जो तत्व ज्ञान के लिए आवश्यक</p> </li>
                            <li><p>स्वर योग- पंच तत्व और पंच तत्व से रोग निदान </p> </li>
                            <li><p>योग चिकित्सा स्वर चिकित्सा स्वर योग से रोग निदान</p> </li>
                        </ul>

                    </div>
                </div>
            </div>

        </section>
    )
}

export default ActionSection
