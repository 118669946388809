import React from "react";
import "./Blog_inner_content.css";
import Leave_Comment from "../Leave_Comment/Leave_Comment";
import Comments_card_holder from "../../../CommonComponents/Comments_card_holder/Comments_card_holder";

function Blog_inner_content({ description }) {
  return (
    <>
      <section className="Blog_inner_content">
        <div className="container">
          <div className="Blog_inner_content_holder">

            <div

              dangerouslySetInnerHTML={{ __html: description }}
            ></div>




            {/* Static Data */}

            {/* <div className="main-top-text-holder">
              <p>
                In today’s fast-paced world, stress, mental fatigue, and
                emotional imbalances have become all too common. While many turn
                to external solutions, pranayama, the ancient yogic practice of
                breath control, offers a powerful internal tool to restore
                balance, reduce stress, and sharpen the mind. By mastering
                simple yet profound pranayama techniques, you can harness the
                power of your breath to enhance your overall well-being.
                <br />
                Pranayama involves controlling the breath in specific ways to
                influence the flow of prana, or life force, in the body. Let’s
                explore how pranayama can benefit your mind and emotions, with a
                focus on two key techniques: Nadi Shodhana (Alternate Nostril
                Breathing) and Kapalabhati (Skull Shining Breath).
              </p>
            </div>

            <div className="list-side-content-holder">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="list-holder">
                    <ol>
                      <li>
                        <div className="list-text-holder">
                          <h3 className="list-main-heading">
                            {" "}
                            <strong> Stress Reduction with Pranayama</strong>
                          </h3>
                          <p>
                            Stress often results from an overactive mind and
                            tense body, where the sympathetic nervous system
                            (the fight-or-flight response) dominates. Pranayama
                            helps activate the parasympathetic nervous system,
                            promoting relaxation and helping the body return to
                            a state of balance.
                          </p>

                          <h3 className="main-text-holder">
                            Nadi Shodhana (Alternate Nostril Breathing){" "}
                          </h3>
                          <h3>
                            {" "}
                            <strong>How it works:</strong>
                          </h3>
                          <p>
                            Nadi Shodhana helps calm the nervous system by
                            balancing the energy flow between the left and right
                            hemispheres of the brain. The practice of
                            alternating between the nostrils during breathing
                            enhances oxygen flow and reduces anxiety.{" "}
                          </p>
                          <h3 className="">
                            <strong>How to do it:</strong>
                          </h3>
                          <ul>
                            <li>
                              <p>
                                Sit in a comfortable position with your spine
                                straight.
                              </p>
                            </li>
                            <li>
                              <p>
                                Close your right nostril with your thumb and
                                inhale deeply through your left nostril.
                              </p>
                            </li>
                            <li>
                              <p>
                                Close your left nostril, release your thumb, and
                                exhale through your right nostril.
                              </p>
                            </li>
                            <li>
                              <p>Repeat for 5-10 minutes.</p>
                            </li>
                          </ul>
                          <h3 className="">
                            <strong>Benefits:</strong>
                          </h3>

                          <p>
                            Kapalabhati clears the mind, increases
                            alertness, and helps combat mental fatigue. It's
                            excellent for improving memory, concentration, and
                            overall mental clarity.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="list-text-holder">
                          <h3 className="list-main-heading">
                            {" "}
                            <strong>Improving Mental Clarity</strong>
                          </h3>
                          <p>
                            Pranayama clears mental fog and enhances
                            concentration by increasing oxygen intake and
                            boosting brain function. The rhythmic nature of
                            breathing techniques also helps synchronize brain
                            waves, leading to improved focus and cognitive
                            function.
                          </p>

                          <h3 className="main-text-holder">
                            Kapalabhati (Skull Shining Breath)
                          </h3>
                          <p className="main-list-text">
                            This technique involves quick, forceful exhalations
                            followed by passive inhalations, energizing the
                            mind, cleansing the lungs, and stimulating the
                            prefrontal cortex.
                          </p>
                          <h3 className="">
                            <strong>How it works:</strong>
                          </h3>
                          <p>
                            Kapalabhati involves quick, forceful exhalations
                            followed by passive inhalations. This technique
                            energizes the mind, cleanses the lungs, and
                            stimulates the brain’s prefrontal cortex, which is
                            linked to decision-making and focus.
                          </p>
                          <h3 className="">
                            <strong>How to do it:</strong>
                          </h3>
                          <ul>
                            <li>
                              <p>
                                Sit comfortably with hands resting on your
                                knees.
                              </p>
                            </li>
                            <li>
                              <p>
                                Inhale deeply and exhale forcefully through your
                                nose, drawing your abdomen in with each
                                exhalation.
                              </p>
                            </li>
                            <li>
                              <p>
                                Perform 20-30 quick breaths in one round. Rest
                                and repeat for 3-5 rounds.
                              </p>
                            </li>
                          </ul>
                          <h3 className="">
                            <strong>Benefits:</strong>{" "}
                          </h3>
                          <p>
                            Increases alertness, clears the mind, and supports
                            mental focus and clarity.
                          </p>
                        </div>
                      </li>

                      <li>
                        <div className="list-text-holder">
                          <h3 className="list-main-heading">
                            <strong>
                              Emotional Balance Through Breath Control
                            </strong>{" "}
                          </h3>
                          <p>
                            Our breath is closely linked to our emotional state.
                            When we're stressed or upset, our breath becomes
                            shallow and irregular. Pranayama helps regulate
                            emotions by smoothing out the breath, calming the
                            mind, and restoring emotional equilibrium.
                          </p>
                          <h3 className="main-text-holder">
                            Nadi Shodhana for Emotional Balance
                          </h3>
                          <h3 className="">
                            <strong>Why it helps:</strong>
                          </h3>{" "}
                          <p>
                            This technique is ideal for balancing emotions
                            because it works with the body’s subtle energy
                            channels (nadis) to bring harmony between the
                            brain's logical and emotional sides. It soothes
                            emotional turbulence and reduces mood swings.
                          </p>
                          <h3 className="">
                            <strong>When to use it:</strong>
                          </h3>{" "}
                          <ul>
                            <li>
                              {" "}
                              <p>
                                Nadi Shodhana can be particularly helpful during
                                times of emotional stress or when feeling
                                overwhelmed. Practicing this technique for just
                                a few minutes can help restore emotional
                                stability.
                              </p>
                            </li>

                            <li>
                              <p> Kapalabhati for Emotional Cleansing</p>
                            </li>
                          </ul>
                          <h3>
                            <strong>Why it helps:</strong>
                          </h3>
                          <ul>
                            <li>
                              <p>
                                {" "}
                                Kapalabhati’s rapid exhalations help to "detox"
                                the mind by releasing pent-up emotional energy.
                                It can also uplift your mood, making it a great
                                practice when you're feeling low or unmotivated.
                              </p>
                            </li>

                            <li>
                              <p>
                                {" "}
                                When to use it: This technique is best practiced
                                in the morning or when you need a quick mental
                                and emotional boost, as it energizes the body
                                and clears negative emotions.
                              </p>
                            </li>
                          </ul>
                        </div>
                      </li>
                    </ol>
                  </div>

                  <div className="Leave_Comment_section">
                    <Leave_Comment />
                  </div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}

export default Blog_inner_content;
