import React, { useEffect } from 'react'
import "../../OurServices.css";
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import BatchSection from '../../CommonServiceComponent/BatchSection/BatchSection';
import BannerTypeOne from '../../../CommonComponents/HomeBanner/BannerTypeOne';
import Description from '../../CommonServiceComponent/Description/Description';
import EnrollSection from '../../CommonServiceComponent/EnrollSection/EnrollSection';
import BannerTypeTwo from '../../../CommonComponents/HomeBanner/BannerTypeTwo';
import BannerSection from '../../CommonServiceComponent/BannerSection/BannerSection';


const AdvancePranayam = () => {

    useEffect(()=>{
        window.scrollTo({
            top: 800, // Scroll to the bottom of the page
            behavior: "smooth", // Smooth scrolling animation
          });
    },[])


    const activities = [
        "Basics/theories behind pranayam",
        "Types of pranayam",
        "Advantages of pranayam",
        "Do’s and Don’t of pranayam"

    ]


     

    const title = "This is for house holders and as well as teen age children who have gone through the basic pranayama courses 5 day program for 1 hrs daily online class"

    const para1 = "This is mainly for the adults as well as teen as students who are well practised in basic pranayam course, the main aim of this course is to level up the interest of pranayam in the participants and give them deep understanding of pranayam so that they can get the insights in their life so that they can get a reflection in their life events and on this basis can drive their life path in a prosperous way in every aspect of lives. It is 7 day course and is 1 hr daily online session only from the Zoom platform."
    const para2 = "The main aim of this course is to empower the participants to get into meditative states easily by calming their minds."


    const program = "Online Advance Pranayam"

    return (
        <section className="our_services">
            <div className="container-fluid p-0">


                <BannerSection src="/Assets/Images/HomeBanner/img4.png" btn_name="Join Now" btn_color="white"  btnText={" Join Now"}/>

                <div className='container'>

                    <BatchSection activities={activities} title={title}/>
                    <Description para1={para1} para2={para2} />                   
                    <EnrollSection program={program} />

                </div>


            </div>
        </section>
    )
}

export default AdvancePranayam
