import React from "react";
import "./Benefits.css";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";


const Benefits = () => {
  return (
    <section className="Benefits">
      <div className="container">
        <div className="main_div">
          <div className="heading">
            <h3>
              <span>Benefits Of </span>
            </h3>
            <h3>Yoga & Meditation</h3>
          </div>

          <div className="description">
            <p>
              Yoga and meditation enhance physical flexibility, strength, and
              balance, while promoting mental clarity, focus, and emotional
              well-being. Regular practice helps reduce stress, improve
              breathing, boost energy levels, and foster inner peace, creating
              harmony between the mind and body.
            </p>
          </div>

          <div className="benefits_div">
            <div className="image-col-row">
              <Swiper
                pagination={{
                  dynamicBullets: true,
                }}
                modules={[Pagination]}
                className="mySwiper service-slider"
                slidesPerView={3}
                breakpoints={{
                  280: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 10,
                  },
                  769: {
                    slidesPerView: 4,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                  1280: {
                    slidesPerView: 4,
                    spaceBetween: 40,
                  },
                }}
              >
                <SwiperSlide>
                  <div className="image-col">
                    <div>

                      <div className="icon_holder">
                        <div className="logo_holder">
                          <img
                            className="logo_img"
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/Images/Home/Benefits/img1.png"
                            }
                          />
                        </div>
                      </div>
                      <div className="heading">
                        <h4>Reliving Stress</h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="image-col">
                    <div>
                      <div className="icon_holder">
                        <div className="logo_holder">
                          <img
                            className="logo_img"
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/Images/Home/Benefits/img2.png"
                            }
                          />
                        </div>
                      </div>
                      <div className="heading">
                        <h4>Peaceful Mind</h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className=" image-col">
                    <div>
                      <div className="icon_holder">
                        <div className="logo_holder">
                          <img
                            className="logo_img"
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/Images/Home/Benefits/img3.png"
                            }
                          />
                        </div>
                      </div>
                      <div className="heading">
                        <h4>Mental Health</h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className=" image-col">
                    <div>
                      <div className="icon_holder">
                        <div className="logo_holder">
                          <img
                            className="logo_img"
                            src={
                              process.env.PUBLIC_URL +
                              "/Assets/Images/Home/Benefits/img4.png"
                            }
                          />
                        </div>
                      </div>
                      <div className="heading">
                        <h4>Reduce Anxiety</h4>
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
