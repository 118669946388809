import React, { useEffect } from 'react'
import "../../OurServices.css";
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import BatchSection from '../../CommonServiceComponent/BatchSection/BatchSection';
import BannerTypeOne from '../../../CommonComponents/HomeBanner/BannerTypeOne';
import Description from '../../CommonServiceComponent/Description/Description';
import EnrollSection from '../../CommonServiceComponent/EnrollSection/EnrollSection';
import BannerTypeTwo from '../../../CommonComponents/HomeBanner/BannerTypeTwo';
import BannerSection from '../../CommonServiceComponent/BannerSection/BannerSection';
import SwaryogaHeading from '../../CommonServiceComponent/SwaryogaHeading/SwaryogaHeading';
import TransformationProgram from '../../CommonServiceComponent/TransformationProgram/TransformationProgram';
import ActionSection from '../../CommonServiceComponent/ActionSection/ActionSection';
import SuccessSection from '../../CommonServiceComponent/SuccessSection/SuccessSection';


const SwarYoga = () => {

    useEffect(()=>{
        window.scrollTo({
            top: 800, // Scroll to the bottom of the page
            behavior: "smooth", // Smooth scrolling animation
          });
    },[])

    const program = "Swar Yoga for Self-Realization"

    const title_heading = [
        {
            title:"स्वर योग - ",
            descn:"शिव स्वरोदय एक ऐसा अद्भुत ग्रंथ है जो हमें लौकिक और पारलौकिक दोनों सफलताएं दिलाता हैं। अधिकतर हमारे ग्रंथ मोक्ष,मुक्ति/ लिबरेशन पर ज्यादा बात करते हैं लेकिन स्वर योग एक ऐसा ग्रंथ है जो लौकिक और पारलौकिक दोनों सफलता की बात करता हे "
        },
        {
            title:"लौकिक अर्थात व्यवहारिक सफलता - ",
            descn:"हम संसार में कैसे रहे? कैसे सफल हो ? हमारा स्वास्थ्य कैसे ठीक रहे? हमारे मनोरथ कैसे सिद्ध हो ? हमारे रुके हुए काम कैसे आगे बढ़े ? आपसी संबंध वापस कैसे अच्छे हो? इसे हम स्वर योग लेवल वन में Cover करते है _ जो हर माह के प्रथम सप्ताह से शुरू होता है।"
        }
    ]

    const transformation_program = [
        "Complete Course on Swar Yoga as per Text- Shiv Svaroday & Svar Yoga Book By Swami SatyaNand Ji Saraswati",
        "सुबह 6:30am से 8:00am बजे यह कोर्स रहेगा",
        "इसमें प्रतिदिन योग प्राणायाम और साथ में थ्योरी की क्लास होगी",
        "अवधि- 15 दिन",
        "नए साधकों के लिए इसकी फीस ₹2000 रखी गई है",
        "नए साधकों के लिए इसकी फीस ₹2000 रखी गई है (पुराने साधकों के लिए फीस 1200Rs)"

    ]


    
    const swar_yogas = [
        "जिसमें प्रथम दिन प्राण और आयुष, प्राणायाम रिवीजन के बाद",
        "दूसरे दिन-मैं कौन हूं?",
        "मेरे जीवन का उद्देश्य क्या है?",
        "मेरा ऑपरेटिंग मैनुएल ? मुझे क्या स्वाध्याय करना चाहिए ?",
        "अष्टांग योग प्रत्याहार, पंच क्लेश जो दुखों के कारण है",
        "फिर अहंकार, ईर्ष्या, काम, क्रोध, लोभ, मोह, कनफ्लिक्ट, स्ट्रेस, ऑप्टिमिस्टिक- पैसिमिस्टिक इन पर कैसे विजय पाएं ?",
        "How to Live a Happy Life",
        "How to Resolve Pending Emotional Issues",
        "गीता चैप्टर 12- ज्ञानी भक्ति के लक्षण",
        "जगतगुरु शंकराचार्य के वचन और बाद में",
        "अष्टावक्र गीता, जो बड़ी अद्भुत है|",
        "ये 19 सितंबर से- साँय 7:00 से 8:00 बजे, शुरू होगा",

    ]


    return (
        <section className="our_services">
            <div className="container-fluid p-0">

            <BannerTypeOne src="/Assets/Images/HomeBanner/img3.png" btn_name="Join Now" btnText={" Join Now"} />

                <div className='container'>

                    <SwaryogaHeading title_heading={title_heading}/>

                    <TransformationProgram left_title="SYL1- Swar Yoga" right_title="Life Transformation Program Based on Swar Yoga" transformation_program={transformation_program}/>

                    <ActionSection/>

                    <TransformationProgram left_title="Course 2" right_title="Swar Yoga for Self Realization" transformation_program={swar_yogas}/>

                    <SuccessSection/>

                    <EnrollSection program={program} contact_no={"+91 9413394288"}/>

                </div>


            </div>
        </section>
    )
}

export default SwarYoga
