import React, { useContext, useEffect, useState } from "react";
import "./Blogs.css";
import {
  faArrowLeft,
  faArrowRight,
  faCalendar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { GetBlogBanner, Getblogs, GetRecentBlogs } from "../../utils/apis/blogs/blogs";
import { Context } from "../../utils/context";

const Blogs = () => {
  const { IMG_URL } = useContext(Context);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 8;

  const [blogsData, setBlogsData] = useState([])
  const [banner, setBanner] = useState([])
  const [recentBlogs, setRecentBlogs] = useState([])


  const callBlogsApi = async () => {
    const res = await Getblogs();

    if (res?.success) {
      setBlogsData(res?.data)
    }
  }


  const callBlogBanner = async () => {
    const res = await GetBlogBanner();

    if (res?.success) {
      setBanner(res?.data?.[0])
    }
  }

  const callRecentBlogs = async () => {
    const res = await GetRecentBlogs();

    if (res?.success) {
      setRecentBlogs(res?.data)
    }
  }


  console.log("recentBlogs", recentBlogs)



  useEffect(() => {
    callBlogsApi()
    callBlogBanner()
    callRecentBlogs()
  }, [])


  // Example data, replace with your actual data array
  const data = [
    /* Array of data objects, each representing an item */
    {
      id: 1,
      title: "The Power of Pranayama",
      desc:
        "Auto insurance is essential for every vehicle owner, providing financial protection in case of accidents, theft, or damage. Whether you’re a new driver or an experienced one, understanding your auto insurance policy can help you make informed decisions and ensure you're properly covered. Here's what you need to know about auto insurance",
    },
    {
      id: 2,
      title: "The Power of Pranayama",
      desc:
        "Auto insurance is essential for every vehicle owner, providing financial protection in case of accidents, theft, or damage. Whether you’re a new driver or an experienced one, understanding your auto insurance policy can help you make informed decisions and ensure you're properly covered. Here's what you need to know about auto insurance",
    },


  ];

  const totalPages = Math.ceil(blogsData?.length / itemsPerPage);

  // Calculate the data to display based on the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const displayedItems = blogsData?.slice(startIndex, startIndex + itemsPerPage);

  // Handlers for page navigation
  const goToNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const goToPreviousPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const goToPage = (page) => {
    setCurrentPage(page);
  };


  const renderMedia = (item) => {
    const fileExtension = item?.image?.split('.').pop().toLowerCase(); // Get the file extension

    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(fileExtension)) {
      // If it's an image
      return (
        <img
          className="logo_img"
          src={`${IMG_URL}/${item?.image}`}
          alt="Logo"
        />
      );
    } else if (['mp4', 'webm', 'ogg'].includes(fileExtension)) {
      // If it's a video
      return (
        <video className="logo_img" loop muted autoPlay>
          <source src={`${IMG_URL}/${item?.image}`} type={`video/${fileExtension}`} />
          Your browser does not support HTML5 video.
        </video>
      );
    } else {
      return <span>Unsupported file type</span>; // If file type is unsupported
    }
  };

  return (
    <section className="Blogs">
      <div className="container-fluid p-0">
        <div className="img_holder">
          <img
            className="logo_img"
            src={IMG_URL + banner?.image}
          />
        </div>
      </div>

      <div className="container">
        <div className="heading_holder">
          <div className="heading">
            <h1>Blogs</h1>
          </div>
          <div className="sub_heading">
            <h1>Blogs</h1>
          </div>
        </div>

        <div className="data_holder">
          <div className="row">
            <div className="col-lg-9 col-md-12">
              <div className="main_card_holder">
                <div className="row">
                  {displayedItems.map((item) => (
                    <div key={item.id} className="col-lg-6 col-md-6 col-sm-12 ">
                      <Link to={`/blogs-inner/${item.id}`}>
                        <div className="card_holder">
                          <div className="img_box_holder">
                            <div className="img_holder">
                              {/* <img
                                className="logo_img"
                                src={`${IMG_URL + "/" + item?.image}`}
                                alt="Logo"
                              /> */}

                              {renderMedia(item)}
                            </div>
                            <div class="overlay-holder"></div>
                            <div className="text_holder">
                              <p className="para1">{item.title1}</p>
                              <p className="para2">
                                {item.title2}
                              </p>
                            </div>
                          </div>

                          <div
                            className="desc_holder"
                            dangerouslySetInnerHTML={{ __html: item.description }}
                          ></div>
                          {/* <div className="desc_holder">
                          <p>{item.description}</p>
                        </div> */}

                          <div className="card_footer">
                            <p>Read More</p>
                          </div>

                        </div>
                      </Link>
                    </div>
                  ))}

                  <div className="pagination_div">
                    <div className="pagination_holder">
                      <nav aria-label="Page navigation example">
                        <ul className="pagination">
                          <li className="page-item" onClick={goToPreviousPage}>
                            <a
                              className="page-link"
                              href="#"
                              aria-label="Previous"
                            >
                              <span aria-hidden="true">
                                <FontAwesomeIcon icon={faArrowLeft} />
                              </span>
                            </a>
                          </li>
                          {[...Array(totalPages)].map((_, index) => (
                            <li
                              key={index + 1}
                              className={`page-item ${currentPage === index + 1 ? "active" : ""
                                }`}
                            >
                              <a
                                className="page-link"
                                href="#"
                                onClick={() => goToPage(index + 1)}
                              >
                                {index + 1}
                              </a>
                            </li>
                          ))}
                          <li className="page-item" onClick={goToNextPage}>
                            <a className="page-link" href="#" aria-label="Next">
                              <span aria-hidden="true">
                                <FontAwesomeIcon icon={faArrowRight} />
                              </span>
                            </a>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-3 col-md-4">
              <div className="recent_blog_div">
                <div className="heading_div">
                  <p>Recent Blogs</p>
                </div>
              </div>
              <div className="blogs_holder">
                <div className="blogs_card_holder">

                  {blogsData?.length > 0 &&
                    blogsData?.map((item) => {

                      const dateObj = new Date(item?.created_at);
                      const options = { day: "numeric", month: "short", year: "numeric" };
                      const formattedDate = dateObj.toLocaleDateString("en-GB", options);

                      return (
                        <>
                          <div className="card">

                            <div className="row">
                              <div className="col-lg-4 col-md-4 col-sm-4 col-3">
                                <div className="left_img_holder">
                                {renderMedia(item)}


                                </div>

                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-8 col-9">
                                <div className="blog_text_holder">
                                  <div className="heading">
                                    <p>{item?.title1}</p>
                                  </div>
                                  <div className="date_holder">
                                    <p>
                                      <FontAwesomeIcon icon={faCalendar} />
                                      <span>{formattedDate}</span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>


                          </div>

                          <hr />
                        </>

                      )
                    })
                  }


                  {/* <div className="card">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-4 col-3">
                        <div className="left_img_holder">
                          <img
                            className="logo_img"
                            src={`${process.env.PUBLIC_URL}/Assets/Images/blogs/img1.png`}
                            alt="Logo"
                          />
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-8 col-sm-8 col-9">
                        <div className="blog_text_holder">
                          <div className="heading">
                            <p>Tips for Choosing the Right Auto Insurance</p>
                          </div>
                          <div className="date_holder">
                            <p>
                              <FontAwesomeIcon icon={faCalendar} />
                              <span>12 Jan 2024</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}



                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Blogs;
