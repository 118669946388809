import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import "./BannerSection.css";
import { useNavigate } from 'react-router-dom';
import ContactLottieModal from '../../../CommonModal/ContactLottieModal';

const BannerSection = (props) => {

    const [modalShow, setModalShow] = useState(false);

    const navigate = useNavigate("");

    console.log("props?.redirectTo", props?.redirectTo);

    const callFunction = () => {

        console.log("asdasdadasd")

        if (props?.redirectTo !== undefined) {
            navigate(props?.redirectTo);
        } else {
            setTimeout(() => {
                setModalShow(false);
            }, 3000);
            setModalShow(true);
        }
    };

    return (
        <div className='BannerSection'>
            <div className="image_holder p-0">
                <img
                    className="ban_image"
                    src={process.env.PUBLIC_URL + props?.src}
                    alt="Logo"
                />
            </div>

            <div className='explore_btn_holder'>
                <Button
                    onClick={() => {
                        callFunction();
                    }}
                    className="explore_black_btn" type="submit">
                    {props?.btn_name}<span><FontAwesomeIcon icon={faCircleArrowRight} className="arrow-icon" /></span>
                </Button>

            </div>
            <ContactLottieModal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
    )
}

export default BannerSection
