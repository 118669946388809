    import React, { useEffect } from 'react'
    import "../../OurServices.css";
    import { faCalendar } from '@fortawesome/free-solid-svg-icons';
    import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
    import { Button } from 'react-bootstrap';
    import BatchSection from '../../CommonServiceComponent/BatchSection/BatchSection';
    import BannerTypeOne from '../../../CommonComponents/HomeBanner/BannerTypeOne';
    import Description from '../../CommonServiceComponent/Description/Description';
    import EnrollSection from '../../CommonServiceComponent/EnrollSection/EnrollSection';
    import BannerTypeTwo from '../../../CommonComponents/HomeBanner/BannerTypeTwo';
    import BannerSection from '../../CommonServiceComponent/BannerSection/BannerSection';


    const OnlinePranayam = () => {

        useEffect(()=>{
            window.scrollTo({
                top: 800, // Scroll to the bottom of the page
                behavior: "smooth", // Smooth scrolling animation
              });
        },[])


        const activities = [
            "Basics/theories behind pranayam",
            "Types of pranayam",
            "Advantages of pranayam",
            "Do’s and Don’t of pranayam"

        ]

        const title = "This would include 1 month daily routine of 1 hr each day involving following activities every day"

        const para1 = "Our online pranayama classes are designed specifically for teenagers to help them manage stress, improve focus, and boost overall well-being. Through simple yet effective breathing techniques, we guide teens in developing mindfulness, emotional balance, and mental clarity."
        const para2 = "These sessions are perfect for enhancing physical and mental health during this crucial stage of growth, all from the comfort of home. In today’s fast-paced world, teenagers often face pressures from academics, social media, and personal expectations. These stresses can lead to anxiety, lack of focus, and even emotional instability. Pranayama, the ancient practice of controlled breathing, offers teens an effective way to manage stress, improve concentration, and nurture emotional well-being."


        const program = "Online pranayam for teen age children"

        return (
            <section className="our_services">
                <div className="container-fluid p-0">


                    <BannerSection src="/Assets/Images/HomeBanner/img2.png" btn_name="Join Now" btn_color="white" btnText={" Join Now"} />

                    <div className='container'>

                        <BatchSection activities={activities} title={title}/>
                        <Description para1={para1} para2={para2} />                   
                        <EnrollSection program={program} />

                    </div>


                </div>
            </section>
        )
    }

    export default OnlinePranayam
