import React, { useState } from 'react'
import './ContactLottieModal.css'
import Modal from 'react-bootstrap/Modal';
import ContactLottie from '../CommonComponents/Lotties/ContactLottie/ContactLottie';


const ContactLottieModal = (props) => {

    const [modalShow,setModalShow] = useState(false)

    return (
        <>
            <Modal
                {...props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className='ContactLottieModal'
            >
                
                <Modal.Body>

                    <div className='main_div'>
                        <div className='lottie_holder'>
                        <ContactLottie/>
                        </div>
                        <p className='descn'>For more information about fees and course schedule </p>

                        <p className='contact'>Please WhatsApp/Call : {props?.contact_no ? props?.contact_no : "+91 9871655591"}</p>
                    </div>

                        
                </Modal.Body>

            </Modal>            
        </>
    )
}

export default ContactLottieModal