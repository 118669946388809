import React, { useContext, useRef } from 'react'
import BellLottie from '../../CommonComponents/Lotties/BellLottie/BellLottie'
import './Bell.css'
import { BellCountIncrementor } from '../../../utils/apis/bellcount/bellcount';

const Bell = () => {

    const audioRef = useRef(null);
    const lottieRef = useRef(null); // Ref for controlling Lottie animation

    const increamentCount = async () => {

        const res = await BellCountIncrementor();
        if (res?.success) {
            playBellSound()
        }

    }; 

    const playBellSound = () => {
        // Play sound
        if (audioRef.current) {
            const playPromise = audioRef.current.play();
            if (playPromise !== undefined) {
                playPromise
                    .then(() => console.log('Sound played successfully'))
                    .catch((error) => console.error('Error playing sound:', error));
                    
            }
        }

        // Restart Lottie animation (if needed)
        if (lottieRef.current) {
            lottieRef.current.stop(); // Stop the animation
            lottieRef.current.play(); // Replay the animation
        }
    };




    return (
        <div className='Bell'>
            <div className='content_main_div'>
                <div className='bell_main_div'>
                    <div className='loottie_div'>
                        <div className='lottie_holder' onClick={increamentCount} >
                            <BellLottie ref={lottieRef}/>
                        </div>

                    </div>
                    <div className='text_div'>
                        <p>Join</p>
                        <p>Centennial Cheers</p>
                    </div>
                </div>

                <audio ref={audioRef} 
                src={
                    process.env.PUBLIC_URL + "/Assets/Sound/bell.mp3"
                } 
                preload="auto" />
            </div>
        </div>
    )
}

export default Bell
