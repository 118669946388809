import { faCircleArrowRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useState } from 'react'
import { Button } from 'react-bootstrap'
import "./BannerTypeTwo.css";
import { useNavigate } from 'react-router-dom';
import ContactLottieModal from '../../CommonModal/ContactLottieModal';

const BannerTypeTwo = ({btnText , ...props}) => {

    const [modalShow, setModalShow] = useState(false)

    const navigate = useNavigate("")

    console.log("props?.redirectTo", props?.redirectTo)

    const callFunction = () => {
        if (props?.redirectTo !== undefined) {
            navigate(props?.redirectTo)
        }

        else if (props?.scroll) {
            window.scrollTo({
                top: 1500, // Scroll to the bottom of the page
                behavior: "smooth", // Smooth scrolling animation
            });
        }
        else {
            setTimeout(() => {
                setModalShow(false)
            }, 3000);
            setModalShow(true)
        }

    }

    return (
        <div className='BannerTypeTwo'>
            <div className="image_holder p-0">
                <img
                    className="ban_image"
                    src={process.env.PUBLIC_URL + props?.src}
                    alt="Logo"
                />
            </div>

            <div className='explore_btn_holder'>
                <Button
                    onClick={() => {
                        callFunction()
                    }}

                    className="explore_black_btn" type="submit">
                    {btnText} <FontAwesomeIcon icon={faCircleArrowRight} className="arrow-icon" />
                </Button>

            </div>




            <ContactLottieModal
                show={modalShow}
                onHide={() => setModalShow(false)}
            />

        </div>
    )
}

export default BannerTypeTwo
