import React, { forwardRef } from 'react'
import './BellLottie.css'

import Lottie from 'react-lottie';
import * as animationData from '../../../CommonComponents/animation/notification.json';

const BellLottie = forwardRef((props, ref) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        }
    };
    return (
        <>
            <section className='pass_rest_success'>
                <div className='vdo-btn'>
                    <Lottie className="me-auto"
                    ref={ref} 
                        options={defaultOptions}
                    />
                </div>
            </section>
        </>
    )
});

export default BellLottie