import React, { useState } from "react";
import "./Header.css";
import { Link, useLocation } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { faEnvelope, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OurServiceModal from "../OurServiceModal/OurServiceModal";

function Header() {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false); // Control Navbar expanded state
  const [modalShow, setModalShow] = useState(false);

  const handleNavClick = () => setExpanded(false);

  return (
    <>
      <section className="main-header">
        <div className="container">
          <Navbar expand="lg" expanded={expanded} onToggle={setExpanded}>
            <div className="header_holder">
              <div className="row inner_div_holder">
                <div className="col-lg-2 col-md-2 col-sm-4 col-4 order-lg-1 order-md-1 order-sm-1 order-1">
                  <div className="logo_holder">
                    <Link to={"/"}>
                      <img
                        className="logo_img"
                        src={process.env.PUBLIC_URL + "/Assets/Images/Header/logo.png"}
                        alt="Logo"
                      />
                    </Link>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-12 order-lg-2 order-md-2 order-sm-3 order-3">
                  <div className="menu-header-holder">
                    <Navbar.Toggle aria-controls="navbarScroll" onClick={() => setExpanded(!expanded)} />
                    <Navbar.Collapse id="navbarScroll">
                      <Nav className="me-auto my-2 my-lg-0 menu-header" navbarScroll>
                        <Nav.Link
                          as={Link}
                          to={"/"}
                          className={location.pathname === "/" ? "active" : ""}
                          onClick={() =>{
                            handleNavClick()
                            window.scrollTo(0, 0);
                          }}
                        >
                          Home
                        </Nav.Link>

                        <Nav.Link
                          as={Link}
                          to={"/about-us"}
                          className={location.pathname === "/about-us" ? "active" : ""}
                          onClick={handleNavClick}
                        >
                          About Us
                        </Nav.Link>

                        <Nav.Link
                          as={Link}
                          className={location.pathname === "/services" ? "active" : ""}
                          onClick={() => {
                            setModalShow(true);
                            setExpanded(false); // Close the Navbar on modal link click
                          }}
                        >
                          Our Service
                        </Nav.Link>

                        <Nav.Link
                          as={Link}
                          to={"/blogs"}
                          className={location.pathname === "/blogs" ? "active" : ""}
                          onClick={handleNavClick}
                        >
                          Blogs
                        </Nav.Link>

                        <Nav.Link
                          as={Link}
                          to={"/contact-us"}
                          className={location.pathname === "/contact-us" ? "active" : ""}
                          onClick={handleNavClick}
                        >
                          Contact
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </div>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-8 col-8 order-lg-3 order-md-3 order-sm-2 order-2">
                  <div className="contact_holder">
                    <div className="mobile_holder">
                      <div className="icon_holder">
                        <FontAwesomeIcon icon={faPhoneVolume} />
                      </div>
                      <div className="inner_text">
                        <label>+91 9871655591</label>
                      </div>
                    </div>
                    <div className="mobile_holder">
                      <div className="icon_holder">
                        <FontAwesomeIcon icon={faEnvelope} />
                      </div>
                      <div className="inner_text">
                        <label>contact@tobeblissful.in</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Navbar>

          <OurServiceModal show={modalShow} onHide={() => setModalShow(false)} />
        </div>
      </section>
    </>
  );
}

export default Header;
