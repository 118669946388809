import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Container } from "react-bootstrap";
import { faEnvelope, faPhone, faPhoneVolume } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFacebook,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
import { GetAddress } from "../../utils/apis/footer/footer";
const Footer = () => {



  const [addressData, setAddressData] = useState([])


  const callAddressApi = async () => {
    const res = await GetAddress();

    if (res?.success) {
      setAddressData(res?.data?.[0])
    }
  }

  console.log("addressData", addressData)


  useEffect(() => {
    callAddressApi()
  }, [])

  return (
    <>
      <section className="footer_div" >
        <div className="footer_bg_img">
          <div className="container footer_holder">
            <div className="">
              <div>
                <div className="row">
                  <div className="col-xl-5 col-lg-4 col-md-5 col-sm-12">
                    <div className="logo_holder">
                      <Link to={"/"}>
                        <img
                          className="logo_img"
                          src={
                            process.env.PUBLIC_URL + "/Assets/Images/Header/logo.png"
                          }
                        />
                      </Link>
                    </div>

                    <div className="address_holder">
                      <div className="address_1">
                        <p>{addressData?.address1}</p>
                      </div>

                      <div className="address_1">
                        <p>{addressData?.address2}</p>
                      </div>

                    </div>
                  </div>

                  <div className="col-xl-7 col-lg-8 col-md-7 col-sm-12">
                    <div className="content_holder">
                      <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-6" >
                          <h4>Quick Links</h4>
                          <div className="navigation">
                            <Link to={"/"}><p> Home</p></Link>
                            <Link to={"/about-us"}><p> About Us</p></Link>
                            <Link to={"/blogs"}><p> Blogs</p></Link>
                            <Link to={"/privacy-policy"}><p> Privacy Policy</p></Link>
                            <Link to={"/terms-conditions"}><p> Terms and Conditions</p></Link>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                          <h4>Programs</h4>
                          <div className="navigation">
                            <Link to={"/our-services"}><p> 1-Month Boot Camp</p></Link>
                            <Link to={"/our-services/onilne-pranayam"}><p> Pranayam for teen age Children</p></Link>
                            <Link to={"/our-services/advance-pranayam"}><p> Advance Pranayam</p></Link>
                            <Link to={"/our-services/swaryoga"}><p> Swar Yoga for Self Realization</p></Link>
                          </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6 col-6">
                          <h4>Contact</h4>
                          <div className="mobile_holder">
                            <div className="icon_holder">
                              <FontAwesomeIcon icon={faPhoneVolume} />
                            </div>
                            <div className="inner_text">
                              <label>+91 9871655591</label>
                            </div>
                          </div>
                          <div className="mobile_holder">
                            <div className="icon_holder">
                              <FontAwesomeIcon icon={faEnvelope} />
                            </div>
                            <div className="inner_text">
                              <label>contact@tobeblissful.in</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bottom_footer">
                  <div className="data_holder">
                    <div className="copyright_div">
                      <p> © 2024 To Be Blissful. All Rights Reserved. Developed by <Link target="_blank" className="bottom_footer_text" to="https://profcymaglobal.com">Profcyma</Link></p>
                    </div>

                    <div className="follow_us_div">
                      <p>Follow Us </p>
                      {/* <FontAwesomeIcon icon={faFacebook} className="logo_icon"/> */}
                      <a href="https://www.instagram.com/yogagururcsharma/" target="_blank" rel="noopener noreferrer">
                        <FontAwesomeIcon icon={faInstagram} className="logo_icon" />
                      </a>
                    </div>

                  </div>

                </div>
              </div>


            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
