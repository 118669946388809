import React, { useContext, useEffect, useState } from 'react'
import "./SatisfiedCustomer.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import { Context } from '../../../utils/context';
import { GetReviews } from '../../../utils/apis/home/home';

const SatisfiedCustomer = () => {

    const { IMG_URL } = useContext(Context);
    const [reviewsData, setReviewsData] = useState([])

    const callReviewsApi = async () => {
        const res = await GetReviews();

        if (res?.success) {
            setReviewsData(res?.data)
        }
    }

    useEffect(() => {
        callReviewsApi()
    }, [])

    console.log("reviewsData", reviewsData)



    return (
        <section className="SatisfiedCustomer">
            <div className="container">
                <div className='main_div'>
                    <div className='heading'>
                        <h3>Words from</h3>
                        <h3><span>Our Satisfied Customers</span></h3>
                    </div>

                    <div className='data_holder'>
                        <div className="row p-0">
                            <div className="col-md-12 p-0">
                                <div className='swiper_holder'>
                                    <Swiper
                                        spaceBetween={20}
                                        breakpoints={{


                                            576: {
                                                slidesPerView: 1,
                                                spaceBetween: 30,
                                            },

                                            765: {
                                                slidesPerView: 1,
                                                spaceBetween: 30,
                                            },

                                            992: {
                                                slidesPerView: 2,
                                                spaceBetween: 30,
                                            },

                                            1024: {
                                                slidesPerView: 2,
                                                spaceBetween: 30,
                                            },

                                            1200: {
                                                slidesPerView: 2,
                                                spaceBetween: 30,
                                            },
                                        }}
                                        onSlideChange={() => console.log("slide change")}
                                        onSwiper={(swiper) => console.log(swiper)}
                                        pagination={{
                                            dynamicBullets: true,
                                            clickable: true,
                                        }}
                                        autoplay={{
                                            delay: 2000, // 3 seconds delay between slides
                                            disableOnInteraction: false,
                                        }}
                                        navigation={true} // Enable navigation buttons
                                        modules={[Pagination, Navigation]} // Add Navigation here
                                        className="mySwiper pb-5"
                                    >

                                        {reviewsData?.length > 0 && reviewsData?.map((item) => {

                                            const newName = item?.first_name +" "+ item?.last_name?.charAt(0)


                                            return (
                                                <SwiperSlide>
                                                    <div className='card_holder'>
                                                        <div className='heading'>
                                                            <div className="comma_holder">
                                                                <img
                                                                    className="logo_img"
                                                                    src={
                                                                        process.env.PUBLIC_URL + "/Assets/Images/Home/SatisfiedCustomer/comma.png"
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='description'>
                                                            <p>
                                                                {item?.review}
                                                            </p>
                                                        </div>
                                                        <div className='card_footer'>
                                                            <div className='data_holder'>
                                                                <div className="img_holder">
                                                                    <img
                                                                        className="logo_img"
                                                                        src={
                                                                            IMG_URL + item?.image
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className='profile'>
                                                                    <p className='name'>{newName}</p>
                                                                </div>
                                                                <div className='profile'>
                                                                    <p className='designation'>{item?.designation}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            )
                                        })}





{/* 
                                        <SwiperSlide>
                                            <div className='card_holder'>
                                                <div className='heading'>
                                                    <div className="comma_holder">
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/SatisfiedCustomer/comma.png"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className='description'>
                                                    <p>
                                                        The meditation and pranayama techniques have truly helped me manage stress better. The clear step-by-step instructions make it so easy to follow.
                                                    </p>
                                                </div>
                                                <div className='card_footer'>
                                                    <div className='data_holder'>
                                                        <div className="img_holder">
                                                            <img
                                                                className="logo_img"
                                                                src={
                                                                    process.env.PUBLIC_URL + "/Assets/Images/Home/SatisfiedCustomer/ok.jpg"
                                                                }
                                                            />
                                                        </div>
                                                        <div className='profile'>

                                                            <p className='name'>Praful P</p>
                                                            <p className='designation'>Digital Manager</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div className='card_holder'>
                                                <div className='heading'>
                                                    <div className="comma_holder">
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/SatisfiedCustomer/comma.png"
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className='description'>
                                                    <p>
                                                        The meditation and pranayama techniques have truly helped me manage stress better. The clear step-by-step instructions make it so easy to follow.
                                                    </p>
                                                </div>
                                                <div className='card_footer'>
                                                    <div className='data_holder'>
                                                        <div className="img_holder">
                                                            <img
                                                                className="logo_img"
                                                                src={
                                                                    process.env.PUBLIC_URL + "/Assets/Images/Home/SatisfiedCustomer/ok.jpg"
                                                                }
                                                            />
                                                        </div>
                                                        <div className='profile'>

                                                            <p className='name'>Praful P</p>
                                                            <p className='designation'>Digital Manager</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide> */}
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='bg1_holder'>
                        <div className="img_holder">
                            <img
                                className="logo_img"
                                src={
                                    process.env.PUBLIC_URL + "/Assets/Images/Home/SatisfiedCustomer/bg1.png"
                                }
                            />
                        </div>
                    </div>

                    <div className='bg2_holder'>
                        <div className="img_holder">
                            <img
                                className="logo_img"
                                src={
                                    process.env.PUBLIC_URL + "/Assets/Images/Home/SatisfiedCustomer/bg2.png"
                                }
                            />
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default SatisfiedCustomer
