import React, { useEffect } from 'react'
import "./OurServices.css";
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import BannerTypeOne from '../CommonComponents/HomeBanner/BannerTypeOne';
import BatchSection from './CommonServiceComponent/BatchSection/BatchSection';
import Description from './CommonServiceComponent/Description/Description';
import EnrollSection from './CommonServiceComponent/EnrollSection/EnrollSection';


const OurServices = () => {
    

    useEffect(()=>{
        window.scrollTo({
            top: 800, // Scroll to the bottom of the page
            behavior: "smooth", // Smooth scrolling animation
          });
    },[])


    const activities = [
        "15 min pranayama + stretching",
        "35 min meditation",
        "15 min journaling",

    ]

    const para1 = "This Course involves 10 min pranayam , 35 min of meditation, 15 min of journaling ,thus overall 60 mins each day . This Service is for those participants who want to start their day early and have a routine in their lives."
    const para2 = "This is actually a habit builder course that makes the participants to shun the past and makesm habitual to living in their present moment only By journaling we are making the participants express their uppressed and make them light in their thought expressions y meditation we are making the participants get out of their recursive thought patterns and make them alert for their whole day"


    const program = "1-month online boot camp"

    return (
        <section className="our_services">
            <div className="container-fluid p-0">
                {/* <div className='banner'>
                    <div className="image_holder">
                        <img
                            className=""
                            src={process.env.PUBLIC_URL + "/Assets/Images/HomeBanner/img1.png"}
                            alt="Logo"
                        />
                    </div>
                </div> */}

                <BannerTypeOne src="/Assets/Images/HomeBanner/img1.png" btn_name="Join Now" btn_color="white" btnText={" Join Now"}  />

                <div className='container'>


                    <BatchSection activities={activities} />

                    <Description para1={para1} para2={para2} />

                    <EnrollSection program={program} />

                </div>


            </div>
        </section>
    )
}

export default OurServices
