import React from 'react'
import "./SuccessSection.css";

const SuccessSection = () => {
    return (
        <section className='SuccessSection'>

            <div className='main_div'>
                <div className='heading_div'>
                    <h3>पारलौकिक सफलता – Swar Yoga For Self Realisation </h3>

                    <p className='para_1'>
                        ये हमारा स्वर योग लेवल 2 कोर्स है - Swar yoga for Self Unfoldment / Self Realisation <br/>
                        स्वर योग में हम प्राण और आयुष में पढ़ते हैं कि षड रिपु - काम, क्रोध, लोभ, मोह, मद और मत्सर और भी कई कारण हैं जो हमें ध्यान में जाने से रोकते है और हमारी क्रिएटिव आयुष <br/>
                        को कम करते है, हमारी उम्र को कम करते है|
                    </p>

                    <p className='para_2'>
                        भगवान शिव श्लोक संख्या 10, 16, 249 मे बताते है- Swar Yoga is for Self Realization<br/>

                        <span>- अथ स्वरं प्रवक्ष्यामि शरीरस्थस्वरोदयम्। हंसचारस्वरूपेण भवेज्ज्ञानं त्रिकालजम्।।10।।</span><br/>
                        <span>- स्वरे वेदाश्च शास्त्राणि स्वरे गांधर्वमुत्तमम्। स्वरे च सर्वत्रैलोक्यं स्वरं आत्म स्वरुपकम् </span><br/>

                        यह Self Realization का मार्ग है
                    </p>

                </div>
            </div>
        </section>
    )
}

export default SuccessSection
