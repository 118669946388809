import "./banner/Banner.css";
import Banner from "./banner/Banner";
import JoyfullInvestment from "./JoyfullInvestment/JoyfullInvestment";
import Benefits from "./Benefits/Benefits";
import ContactUs from "./ContactUs/ContactUs";
import SatisfiedCustomer from "./SatisfiedCustomer/SatisfiedCustomer";
import ExploreOffer from "./ExploreOffer/ExploreOffer";
import Bell from "./Bell/Bell";

function Home() {
  return (
    <>
      <Banner />   
      <JoyfullInvestment/>     
      <ExploreOffer/>    
      <Benefits/>
      <ContactUs/>
      <SatisfiedCustomer/>
      <Bell />
     
    </>
  );
}

export default Home;
