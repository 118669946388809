import React from "react";
import "./Common_banner.css";

function Common_banner({ imgSrc, mainHeading, heading }) {
  return (
    <>
      <div className="container">
        <section className="Common_banner_holder">
          <div className="banner-img-holder">
            <img
              src={process.env.PUBLIC_URL + imgSrc}
              className="banner-img"
              alt="banner-img"
            />
          </div>

          <div className="banner-heading-holder">
            <div className="heading-holder-section">
              <h2 className="main-heading">{mainHeading}</h2>
              <h3 className="heading">{heading}</h3>
            </div>
          </div>

        </section>
      </div>
    </>
  );
}

export default Common_banner;
