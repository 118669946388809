import React from "react";
import Terms_Conditions_Content from "./Terms_Conditions_Content/Terms_Conditions_Content";
import Common_banner from "../CommonComponents/Common_banner/Common_banner";

function Terms_Conditions() {
  return (
    <>
      <Common_banner
        imgSrc={"/Assets/Images/Terms_Conditions/terms-conditions-banner.png"}
        mainHeading={"Terms & Conditions"}
        heading={"Terms & Conditions"}
      />

      <Terms_Conditions_Content />
    </>
  );
}

export default Terms_Conditions;
