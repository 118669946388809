import React, { useState } from 'react'
import "./AboutUs.css";
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContactUs from '../home/ContactUs/ContactUs';

const AboutUs = () => {

    const [isHovered, setIsHovered] = useState(false);

    // Function to handle mouse enter event
    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    // Function to handle mouse leave event
    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    return (

        <>
            <section className="AboutUs">
                <div className='container-fluid p-0'>
                    <div className="img_holder">
                        <img
                            className="logo_img"
                            src={
                                process.env.PUBLIC_URL + "/Assets/Images/Aboutus/bg.png"
                            }
                        />
                    </div>
                </div>
                <div className='AboutUs_container'>
                   <div className="container">
                    <div className='heading_holder'>
                        <div className='heading'>
                            <h1>About Us</h1>
                        </div>
                        <div className='sub_heading'>
                            <h1>About Us</h1>
                        </div>
                    </div>

                    <div className='para_holder'>
                        <p>Hi , We are trained professionals in yoga, Pranayam, meditation and swar yoga Having overall experience of more then 10 years in teaching online / offline mediums Out main aim is to provide opportunity for holistic development of every person We are offering various courses on Swar yoga, Praynayam, Advanced Pranyam, Meditation, Journaling.</p>
                    </div>

                    <div className='more_info'>
                        <div className='row'>
                            <div className='col-lg-8 col-md-6 col-sm-12 col-12'>
                                <div className='heading_div'>
                                    <p>For more information about fee and course schedule please</p>
                                </div>

                            </div>
                            <div className='col-lg-4 col-md-6 col-sm-12 col-12'>
                                <div className='text_holder'>
                                    <div className='icon_holder'>
                                        <p><FontAwesomeIcon icon={faPhone} /></p>
                                    </div>
                                    <div className='inner_text'>
                                        <p>WhatsApp/call @+91 9871655591</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='profile_holder'>
                        <div className='row'>
                            <div className='col-lg-3 col-md-12'>
                                <div className="profile_card_img_holder">
                                    <div className="img_box_holder">
                                        <div className="img_holder">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/Assets/Images/Aboutus/pro_pic1.png"
                                                }
                                            />
                                            {/* Conditionally add the 'rotate-center' class based on hover state */}

                                        </div>
                                        <div className="bg_color_holder"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-9 col-md-12'>
                                <div className='text_main_div'>
                                    <h2 className='name_heading'>Mr. Ramesh Chandra Sharma</h2>
                                    <p className='exp_tag'> (20+ years of yoga teaching experience)</p>

                                    <div className='intro_div'>
                                        <p className='para1'>
                                            Hi , There I am Ramesh Chandra Sharma, having around 20+ years
                                            of yoga teaching experience, I had been yoga instructor at AIIMS, Jodhpur
                                            for around 5 years as well.
                                        </p>
                                        <p className='para2'>
                                            I have been invited in various lectures on yog as well ,
                                            and I have taught yoga asana about 1000+ students on online platform as well as conducted
                                            personal yoga sessions as well at my house at Jodhpur. So I would be happy to help you in
                                            this exciting journey of yogasana and swar yog
                                        </p>
                                    </div>

                                    <div className='contact_div'>
                                        <div className='row'>
                                            <div className='col-lg-10'>
                                                <div className='main_text_div'>
                                                    <div className='text_holder'>
                                                        <div className='icon_holder'>
                                                            <p><FontAwesomeIcon icon={faPhone} /></p>
                                                        </div>
                                                        <div className='inner_text'>
                                                            <p>: +91 9413394288</p>
                                                        </div>
                                                    </div>

                                                    <div className='text_holder'>
                                                        <div className='icon_holder'>
                                                            <p><FontAwesomeIcon icon={faEnvelope} /></p>
                                                        </div>
                                                        <div className='inner_text'>
                                                            <p>: yogagururcs@gmail.com</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='circle_main_div'>
                                        <div className='main-circle-holder'>

                                        <div className='circle_div'></div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='profile_holder'>
                        <div className='row'>
                            <div className='col-lg-3'>
                                <div className="profile_card_img_holder">
                                    <div className="img_box_holder">
                                        <div className="img_holder">
                                            <img
                                                src={
                                                    process.env.PUBLIC_URL + "/Assets/Images/Aboutus/pro_pic2.png"
                                                }
                                            />
                                            {/* Conditionally add the 'rotate-center' class based on hover state */}

                                        </div>
                                        <div className="bg_color_holder"></div>
                                    </div>

                                </div>
                            </div>
                            <div className='col-lg-9'>
                                <div className='text_main_div'>
                                    <h2 className='name_heading'>MR. Gaurav Sharma</h2>
                                    <p className='exp_tag'> (10+ years of yoga teaching experience)</p>

                                    <div className='intro_div'>
                                        <p className='para1'>
                                            Hi , There I am Gaurav Sharma , having around 10 years of experience
                                            in pranayam and yog practices. I have gone through many pranayam trainings
                                            and want to serve you for your better life so that you can better share your
                                            life and live blissfully.
                                        </p>
                                        <p className='para2'>
                                            My main strength is pranayam and would like to share the benefits of same.
                                        </p>
                                    </div>

                                    <div className='contact_div'>
                                        <div className='row'>
                                            <div className='col-lg-10'>
                                                <div className='main_text_div'>
                                                    <div className='text_holder'>
                                                        <div className='icon_holder'>
                                                            <p><FontAwesomeIcon icon={faPhone} /></p>
                                                        </div>
                                                        <div className='inner_text'>
                                                            <p>: +91 9871655591</p>
                                                        </div>
                                                    </div>

                                                    <div className='text_holder'>
                                                        <div className='icon_holder'>
                                                            <p><FontAwesomeIcon icon={faEnvelope} /></p>
                                                        </div>
                                                        <div className='inner_text'>
                                                            <p>: contact@blissful.in</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                   </div>
                </div>
            </section>

            <ContactUs />
        </>





    )
}

export default AboutUs
