import React from 'react'
import "./ExploreOffer.css";
import { faCalendar, faCircleArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const ExploreOffer = () => {

    const navigate = useNavigate("")

    return (
        <section className="ExploreOffer">
            <div className="container">
                <div className='main_div'>
                    <div className='heading'>
                        <h3><span>Explore</span></h3>
                        <h4>Our Transformative Offerings</h4>
                        <p>Pick a program. You’re one step away from reclaiming your life.</p>
                    </div>


                    <div className='content_holder'>
                        <div className='row'>
                            <div className='col-lg-10 col-12 order-lg-1  order-md-2 order-sm-2 order-2'>
                                <div className='card_holder'>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <div className='text_holder'>
                                                <h3>1-Month Boot Camp</h3>
                                                <div className='date_holder'>
                                                    <div className='from_date'>
                                                        <p><FontAwesomeIcon icon={faCalendar} /><span>Morning 6 am to 7 am | Monday to Sunday</span></p>
                                                    </div>
                                                </div>
                                                <div className='description'>
                                                    <p>This would include 1 month daily routine of 1 hr each day involving the following activities:</p>
                                                </div>
                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>15 min pranayama + stretching</p>
                                                    </div>
                                                </div>

                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>35 min meditation</p>
                                                    </div>
                                                </div>

                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>15 min journaling</p>
                                                    </div>
                                                </div>

                                                <div className='btn_holder'>
                                                    <Button
                                                        onClick={() => {
                                                            navigate("/our-services")
                                                        }}
                                                        className="submit_btn" type="submit">
                                                        Explore More <FontAwesomeIcon icon={faCircleArrowRight} className="arrow-icon" />
                                                    </Button>

                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='right_text_holder'>
                                                <p>New batches on 1st date of each month</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='img_holder'>
                                        <div className='sub_div'>
                                            <img
                                                className="logo_img"
                                                src={
                                                    process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/img1.png"
                                                }
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className='card_holder'>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <div className='text_holder'>
                                                <h3>Pranayam for teen age Children</h3>
                                                <div className='date_holder'>
                                                    <div className='from_date'>
                                                        <p><FontAwesomeIcon icon={faCalendar} /><span>as per the batches made and availability</span></p>
                                                    </div>
                                                </div>
                                                <div className='description'>
                                                    <p>This would include 1 month daily routine of 1 hr each day involving the following activities:</p>
                                                </div>
                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>Basics/theories behind pranayam</p>
                                                    </div>
                                                </div>

                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>Types of pranayam</p>
                                                    </div>
                                                </div>

                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>Advantages of pranayam</p>
                                                    </div>
                                                </div>

                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>Do’s and Don’t of pranayam</p>
                                                    </div>
                                                </div>

                                                <div className='btn_holder'>
                                                    <Button
                                                        onClick={() => {
                                                            navigate("/our-services/onilne-pranayam")
                                                        }}
                                                        className="submit_btn" type="submit">
                                                        Explore More <FontAwesomeIcon icon={faCircleArrowRight} className="arrow-icon" />
                                                    </Button>

                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='right_text_holder'>
                                                <p>New batches on 1st date of each month</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='img_holder'>
                                        <div className='sub_div'>
                                            <img
                                                className="logo_img"
                                                src={
                                                    process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/img2.png"
                                                }
                                            />
                                        </div>
                                    </div>

                                </div>


                                <div className='card_holder'>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <div className='text_holder'>
                                                <h3>Advance Pranayam</h3>
                                                <div className='date_holder'>
                                                    <div className='from_date'>
                                                        <p><FontAwesomeIcon icon={faCalendar} /><span>as per the batches made and availability</span></p>
                                                    </div>
                                                </div>
                                                <div className='description'>
                                                    <p>This is for house holders and as well as teen age children who have gone through the basic pranayama courses</p>
                                                </div>
                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>Basics/theories behind pranayama</p>
                                                    </div>
                                                </div>

                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>Types of pranayam</p>
                                                    </div>
                                                </div>

                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>Advantages of pranayam</p>
                                                    </div>
                                                </div>

                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>Do’s and Don’t of pranayam</p>
                                                    </div>
                                                </div>

                                                <div className='btn_holder'>
                                                    <Button
                                                        onClick={() => {
                                                            navigate("/our-services/advance-pranayam")
                                                        }}
                                                        className="submit_btn" type="submit">
                                                        Explore More <FontAwesomeIcon icon={faCircleArrowRight} className="arrow-icon" />
                                                    </Button>

                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='right_text_holder'>
                                                <p>New batches on 1st date of each month</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='img_holder'>
                                        <div className='sub_div'>
                                            <img
                                                className="logo_img"
                                                src={
                                                    process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/img3.png"
                                                }
                                            />
                                        </div>
                                    </div>

                                </div>


                                <div className='card_holder'>
                                    <div className='row'>
                                        <div className='col-lg-8'>
                                            <div className='text_holder'>
                                                <h3>Swar Yoga for Self Realization</h3>
                                                <div className='date_holder'>
                                                    <div className='from_date'>
                                                        <p><FontAwesomeIcon icon={faCalendar} /><span>as per the batches made and availability</span></p>
                                                    </div>
                                                </div>
                                                <div className='description'>
                                                    <p>This is for house holders and as well as teen age children who have gone through the basic pranayama courses</p>
                                                </div>
                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>Duration is 15 days</p>
                                                    </div>
                                                </div>

                                                <div className='activty_holder'>
                                                    <div className='icon_holder'>
                                                        <img
                                                            className="logo_img"
                                                            src={
                                                                process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/arrow.png"
                                                            }
                                                        />
                                                    </div>
                                                    <div className='text_holder'>
                                                        <p>Regular Theory Class and pranayam</p>
                                                    </div>
                                                </div>

                                             

                                                <div className='btn_holder'>
                                                    <Button
                                                    onClick={() => {
                                                        navigate("/our-services/swaryoga")
                                                    }}
                                                    className="submit_btn" type="submit">
                                                        Explore More <FontAwesomeIcon icon={faCircleArrowRight} className="arrow-icon" />
                                                    </Button>

                                                </div>


                                            </div>
                                        </div>
                                        <div className='col-lg-4'>
                                            <div className='right_text_holder'>
                                                <p>New batches on 1st date of each month</p>
                                            </div>
                                        </div>
                                    </div>


                                    <div className='img_holder'>
                                        <div className='sub_div'>
                                            <img
                                                className="logo_img"
                                                src={
                                                    process.env.PUBLIC_URL + "/Assets/Images/Home/ExploreOffer/img4.png"
                                                }
                                            />
                                        </div>
                                    </div>

                                </div>



                            </div>
                            <div className='col-lg-2 col-12 order-lg-2  order-md-1 order-sm-1 order-1'>
                                <div className='rightmost_title'>
                                    <h1>Our Four Programs</h1>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    )
}

export default ExploreOffer
